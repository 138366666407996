// 基础配置
let normalServiceUrl = "https://go-endpoint.replit.app";
let modelServiceUrl = "https://zchat-model-service-1-eric166188.replit.app";
let fileServiceUrl = "https://larkfile.replit.app";

// 检查当前主机名
if (window.location.hostname === "localhost") {
    // 本地环境
    modelServiceUrl = "http://localhost:8000";
    // fileServiceUrl = "http://localhost:4000";
    normalServiceUrl = "https://localhost:80";
}

const headers = {
    "OpenAI-Beta": "assistants=v2",
    "Content-Type": "application/json",
};
const assistantId = "asst_5WFdXpWbssQZXOsyI2aeKK44";

async function fetchApi(
    endpoint,
    method = "POST",
    data = {},
    stream = false,
    header = null,
) {
    try {
        const isFormData = data instanceof FormData;

        const response = await fetch(`${normalServiceUrl}${endpoint}`, {
            headers: isFormData ? header : header || headers,
            method: method,
            body: method === "GET" ? null : isFormData ? data : JSON.stringify(data), // 确保 data 始终正确传递
        });

        if (stream) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");
            let result = "";
            let finalResponse = {};

            while (true) {
                const {value, done} = await reader.read();
                if (done) break;
                result += decoder.decode(value, {stream: true});

                const events = result.split("\n\n");
                result = events.pop(); // 保留最后一个未完成的事件

                for (let event of events) {
                    if (event.trim() === "") continue;
                    const [eventTypeLine, dataLine] = event.split("\n");
                    const eventType = eventTypeLine.replace("event: ", "").trim();
                    let eventData;

                    try {
                        eventData = JSON.parse(dataLine.replace("data: ", "").trim());
                    } catch (e) {
                        eventData = dataLine.replace("data: ", "").trim();
                    }

                    if (eventType === "thread.message.completed") {
                        finalResponse = eventData;
                    }
                }
            }
            return finalResponse;
        } else {
            return await response.json();
        }
    } catch (error) {
        // console.error(`Failed to fetch ${endpoint}:`, error);
        return null;
    }
}

//ai生成消息
export async function chatAgainApi(thread_id, assistant_id) {
    const data = {
        thread_id: thread_id,
        assistant_id: assistant_id,
        manual: true,
    };

    const response = await fetch(modelServiceUrl + `/api/chat/chat_again`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData);
        console.log(`Request failed with status ${response.status}`);
    }

    return response.json();
}

// 处理飞书文件（上传到OpenAI）
export async function processLarkFiles(fileTokens) {
    const data = {
        file_tokens: fileTokens // 飞书文件 token 列表
    };

    try {
        const response = await fetch(fileServiceUrl + '/api/process_lark_files', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error:', errorData);
            console.log(`Request failed with status ${response.status}`);
            // throw new Error('Failed to process lark files');
        }

        return response.json();
    } catch (error) {
        console.error('Error processing lark files:', error);
        throw error;
    }
}

// 发送聊天请求（发起对话）
export async function postRunByThreadBotCloud(
    thread_id,
    instructions,
    mode,
    employee_id = null,
    background = null,
    assistant_id = assistantId,
) {
    let data = {
        thread_id: thread_id,
        assistant_id: assistant_id,
        instructions: instructions,
        chat_mode: mode,
        employee_id: employee_id,
        background: background,
        manual: true,
    };

    const response = await fetch(modelServiceUrl + `/api/chat/run_thread`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData);
        console.log(`Request failed with status ${response.status}`);
    }

    return response.json();
}


export async function analyzeImage(prompt, image_url) {
    const messages = [
        {
            role: "user",
            content: [
                {
                    type: "text",
                    text: prompt,
                },
                {
                    type: "image_url",
                    image_url: {url: image_url},
                },
            ],
        },
    ];

    try {
        const response = await fetch(`${modelServiceUrl}/api/image/explain`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({messages}), // 包装成对象
        });

        if (!response.ok) {
            console.log(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error analyzing image:", error);
        throw error;
    }
}


export async function chatGroup(data) {
    try {
        const response = await fetch(`${modelServiceUrl}/api/chat/chat_group`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // 包装成对象
        });

        if (!response.ok) {
            console.log(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error analyzing image:", error);
        throw error;
    }
}


// 弃用的旧接口
export async function generatePic(img_url) {
    const prompt = `角色背景
 你是一个高效的图片分析员，能够准确地读取图片内容，并生成简洁明了的图片标题和详细的图片描述（你生成的文件和描述后面会被工作人员进行检索）
 任务1:生成图片标题
 1. 仔细阅读文件内容，提取关键信息。
 2. 根据关键信息，生成一个简洁且能概括文件主要内容的标题。
 3. 标题长度控制在 20 个字以内。
 
 任务2:生成图片描述
 1. 根据分析图片内容，梳理出主要内容和重要细节。
 2. 用清晰、流畅的语言将这些内容组织成图片描述。
 3. 图片描述长度控制在 100 字以内。
 ## 限制
 - 只处理与文件内容相关的任务，拒绝回答与文件无关的问题。
 - 所输出的内容必须按照给定的格式进行组织，不能偏离框架要求。
 - 标题和描述要准确反映文件内容并且标题和描述要有关联性。
 
 # 输出格式
 无论什么文件，都请按照以下 JSON 格式输出结果：
 {
   "标题":"图片标题",
   "描述":"图片的描述"
 }`;

    const data = {
        model: "gpt-4o",
        messages: [
            {
                role: "user",
                content: [
                    {
                        type: "text",
                        text: prompt,
                    },
                    {
                        type: "image_url",
                        image_url: {url: img_url},
                    },
                ],
            },
        ],
        response_format: {type: "json_object"},
    };
    return fetchApi("/chat/completions", "POST", data);
}

