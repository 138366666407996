import React, { useEffect, useRef, useState, useMemo } from "react";
import localforage from "localforage";
import { useResponsive } from "../../../utils/useResponsive";
import {
  Avatar,
  Badge,
  Input,
  List,
  Space,
  Menu,
  Dropdown,
  Typography,
  Button,
  Tag,
  Select,
} from "@arco-design/web-react";
import { Cell } from "@arco-design/mobile-react";
import { getWxChatsApi } from "../../../api/normalApi";
import UserChat from "./UserChat";
import { useNavigate } from 'react-router-dom'; // 修改为 useNavigate
import { IconPlus } from "@arco-design/web-react/icon";
import UserManagement from "../Modal/UserManagementModal";
import NotificationSettingModal from "../Modal/NotificationSettingModal";
import { formatMessageTime } from "../../../utils/format";
import OpenAI from "openai";
const openai = new OpenAI({
  baseURL: "https://baseurl.replit.app",
  apiKey: "",
  dangerouslyAllowBrowser: true,
});

const { Search: InputSearch } = Input;


const ChatList = ({
  currentWxAccount,
  setThreadId,
  assistant,
  toWxId,
  setShowUserChat,
  showUserChat,
  onGroupSendClick,
}) => {
  const [chatList, setChatList] = useState([]); // 存储用户数据
  const [currentWxUser, setCurrentWxUser] = useState(null);
  const [timestampList, setTimestampList] = useState([]); //每条最后消息的时间戳
  const wxUserUnreadList = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentAutoReply, setCurrentAutoReply] = useState(false);
  const [manualList, setManualList] = useState([]); // 存储手动回复的消息列表
  const isFetchingUsers = useRef(false);
  const [manageVisible, setManageVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [chatOption, setChatOption] = useState("全部");
  const currentUserRef = useRef(null);
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsersWithWxId();
    // eslint-disable-next-line
  }, [toWxId]);

  const fetchUsersWithCheck = async () => {
    if (isFetchingUsers.current) return;

    try {
      isFetchingUsers.current = true;
      const users = await fetchUsers();
      if (users.length > 0) {
        const updatedUser = users.find(
          (user) => user.thread_id === currentWxUser?.thread_id
        );
        if (updatedUser) {
          setCurrentWxUser(updatedUser); // 更新当前用户
          setCurrentAutoReply(updatedUser.auto_reply); // 更新自动回复状态
        }

        await fetchUsersStatus(users, currentWxUser, setManualList);
      }
    } finally {
      isFetchingUsers.current = false;
    }
  };

  const fetchUsersWithWxId = async () => {
    if (isFetchingUsers.current) return;

    try {
      isFetchingUsers.current = true;
      const users = await fetchUsers();
      if (users.length > 0) {
        const wxIdUser = users.find((user) => user.to_wx_id === toWxId);

        if (wxIdUser) {
          setCurrentWxUser(wxIdUser);
          setCurrentAutoReply(wxIdUser.auto_reply);
          await localforage.setItem(
            `${currentWxAccount.wx_id}-currentWxUser`,
            wxIdUser
          );
        }
        await fetchUsersStatus(users, currentWxUser, setManualList);
      }
    } finally {
      isFetchingUsers.current = false;
    }
  };

  useEffect(() => {
    const initCurrentWxUser = async () => {
      try {
        // 尝试从本地存储获取用户
        const storedUser = await localforage.getItem(
          `${currentWxAccount.wx_id}-currentWxUser`
        );
        if (storedUser) {
          setCurrentWxUser(storedUser);
          setCurrentAutoReply(storedUser.auto_reply);
          setThreadId(storedUser.thread_id);
        }
        // 强制刷新用户列表，确保最新数据
        await fetchUsersWithCheck();
      } catch (error) {
        console.error("Error retrieving currentWxUser:", error);
      }
    };

    if (currentWxAccount) {
      initCurrentWxUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWxAccount]);

  // 每10秒更新一次用户列表
  useEffect(() => {
    if (currentWxAccount) {
      fetchUsersWithCheck();
    }
    const intervalId = setInterval(fetchUsersWithCheck, 10000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [currentWxAccount]);

  // 添加滚动到当前用户位置的效果
  useEffect(() => {
    if (currentWxUser && currentUserRef.current) {
      currentUserRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [currentWxUser]);

  // 将 useMemo 移到这里，组件顶部的其他 hooks 之后
  const sortedChatList = useMemo(() => {
    let filteredList = [...chatList];

    // 根据 chatOption 筛选列表
    switch (chatOption) {
      case "新客":
        filteredList = filteredList.filter((item) => item?.last_message !== "");
        break;
      case "私聊":
        filteredList = filteredList.filter((item) => !item.is_group);
        break;
      case "群聊":
        filteredList = filteredList.filter((item) => item.is_group);
        break;
      default:
        // "全部" 选项不需要过滤
        break;
    }

    // 按原有逻辑排序
    return filteredList.sort((a, b) => {
      const aIsManual = manualList.some(
        (manual) => manual.threadId === a.thread_id
      );
      const bIsManual = manualList.some(
        (manual) => manual.threadId === b.thread_id
      );

      if (aIsManual && !bIsManual) return -1;
      if (!aIsManual && bIsManual) return 1;

      const aTimestamp = timestampList[a.thread_id] || 0;
      const bTimestamp = timestampList[b.thread_id] || 0;
      return bTimestamp - aTimestamp;
    });
  }, [chatList, manualList, timestampList, chatOption]);

  // 添加排序后的未读消息列表
  const sortedUnreadList = useMemo(
    () =>
      sortedChatList.map(
        (item) =>
          wxUserUnreadList.current[
            chatList.findIndex((x) => x.thread_id === item.thread_id)
          ]
      ),
    [sortedChatList, chatList]
  );

  // 获取线程消息
  const getMessageList = async (threadId, num = 10) => {
    try {
      if (!threadId) return null;
      return await openai.beta.threads.messages.list(
        threadId, // 改为使用函数参数
        { limit: num, } // 同时把 limit 改为用 num，而不是写死
      );
    } catch (e) {
      console.error(`Error fetching messages for thread_id ${threadId}:`, e);
      return null;
    }
  };

  //找到未读的第一条消息
  const findFirstReadTimeMessage = (messages) => {
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].metadata && messages[i].metadata.readTime) {
        return i; // 返回第一个匹配的索引
      }
    }
    return -1; // 如果没有找到，返回 -1
  };

  const findManualReplyMessage = (messages) => {
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].metadata && messages[i].metadata.manual === "true") {
        return { index: i, message: messages[i] }; // 返回第一个匹配的索引
      }
    }
    return { index: -1, message: null }; // 如果没有找到，返回 -1
  };

  // 获取对话列表
  const fetchUsers = async () => {
    try {
      const response = await getWxChatsApi(
        String(currentWxAccount.wx_id),
        true
      );

      if (response.status === 200 || response.status === "success") {
        const users = response.data;
        // console.log(users)
        setChatList(users);
        return response.data;
      }
      return [];
    } catch (error) {
      console.error("API Error:", error);
      return [];
    } finally {
      // setLoading(true); // 结束加载状态
    }
  };

  // 获取用户状态
  const fetchUsersStatus = async (chatList, currentWxUser, setManualList) => {
    try {
      // 计算今天凌晨0点的时间
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // 筛选今天有更新的用户
      const todayActiveUsers = chatList.filter(
        (user) =>
          user.updated_at &&
          new Date(user.updated_at) >= today &&
          user.last_message // 添加判断最后一条消息不为空
      );
      // 使用 Map 存储消息获取的 Promise，避免重复获取
      const messagePromises = new Map();

      // 为每个用户创建获取消息的 Promise
      todayActiveUsers.forEach((user) => {
        const messageCount =
          currentWxUser && user.thread_id === currentWxUser.thread_id ? 10 : 10;
        messagePromises.set(
          user.thread_id,
          getMessageList(user.thread_id, messageCount)
        );
      });

      // 并发执行所有 Promise
      const results = await Promise.all([...messagePromises.values()]);

      const newLastTimestamp = {};
      const newManualList = [];
      const unReadMessageCount = [];

      // 处理结果
      todayActiveUsers.forEach((user, index) => {
        const result = results[index];
        if (!result?.data) return;

        // 更新未读消息计数
        unReadMessageCount.push(findFirstReadTimeMessage(result.data));

        // 检查手动回复状态
        const { message } = findManualReplyMessage(result.data);
        if (message) {
          newManualList.push({
            threadId: user.thread_id,
            nickname: user.nickname,
            message,
          });
        }

        // 更新最后消息时间戳
        if (result.data[0]?.created_at) {
          newLastTimestamp[user.thread_id] = result.data[0].created_at;
        }
      });

      // 批量更新状态
      setManualList(newManualList);
      wxUserUnreadList.current = unReadMessageCount;
      setTimestampList(newLastTimestamp);

      return {
        timestamps: newLastTimestamp,
        unreadCounts: unReadMessageCount,
        manualList: newManualList,
      };
    } catch (error) {
      console.error("Error in fetchUsersStatus:", error);
      return null;
    }
  };

  const handleItemClick = async (item, mode) => {
    setShowUserChat(true);
    if (mode === "filter") {
      setSearchTerm(item.nickname);
    } else {
      setSearchTerm("");
      await localforage.setItem(
        `${currentWxAccount.wx_id}-currentWxUser`,
        item
      );
    }
    setCurrentAutoReply(item.auto_reply);
    setCurrentWxUser(item);
    setThreadId(item.thread_id);
  };

  // 筛选符合搜索条件的 chatList
  const filteredChatList = chatList.filter(
    (item) =>
      (item.remark_name &&
        item.remark_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.nickname &&
        item.nickname.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // 创建下拉菜单项
  const dropList = (
    <Menu style={{ width: 280 }}>
      {filteredChatList.map((item) => (
        <Menu.Item
          key={item.thread_id}
          onClick={() => handleItemClick(item, "filter")} // 点击时更新当前用户
        >
          {item.nickname}
        </Menu.Item>
      ))}
    </Menu>
  );

  const renderListItem = (item) => {
    const isCurrentUser =
      currentWxUser && item.thread_id === currentWxUser.thread_id;
    return (
      <List.Item
        key={item.thread_id}
        ref={isCurrentUser ? currentUserRef : null}
        style={{
          backgroundColor:
            item.thread_id === currentWxUser?.thread_id ? "#366EF415" : "",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <div
          key={`dot-${item.thread_id}`} // 添加 key
          style={{
            width: "9px",
            height: "9px",
            borderRadius: "50%",
            backgroundColor: manualList.some(
              (manual) => manual.threadId === item.thread_id
            )
              ? "#F53F3F"
              : item.auto_reply
                ? "#00B42A"
                : "#FF7D00",
            position: "absolute",
            left: 4,
            top: 4,
            zIndex: 1,
          }}
        />
        <List.Item.Meta
          onClick={() => handleItemClick(item)}
          avatar={
            <Badge
              key={`badge-${item.thread_id}`} // 添加 key
              count={
                sortedUnreadList[
                  sortedChatList.findIndex(
                    (x) => x.thread_id === item.thread_id
                  )
                ]
              }
              style={{ marginTop: 2, marginLeft: 4 }}
            >
              <Avatar
                shape="square"
                style={{ backgroundColor: "#3370ff" }}
                triggerIcon={
                  manualList.some(
                    (manual) => manual.threadId === item.thread_id
                  ) ? (
                    <Tag
                      color="red"
                      size="small"
                      style={{
                        width: "52px",
                        fontSize: "11px",
                        marginLeft: "-28px",
                        fontWeight: "bold",
                      }}
                    >
                      需人工
                    </Tag>
                  ) : (
                    ""
                  )
                }
              >
                {item.head_url ? (
                  <img
                    alt="头像"
                    // src={`data:image/png;base64,${item.head_url}`}
                    src={`${item.head_url}`}
                  />
                ) : (
                  item.nickname.charAt(0)
                )}
              </Avatar>
            </Badge>
          }
          title={
            <Space align={"center"}>
              <Typography.Ellipsis style={{ width: 128 }}>
                {item.remark_name || item.nickname}
              </Typography.Ellipsis>
              <Space direction={"vertical"} align={"end"}>
                <div
                  style={{
                    color: "#86909C",
                    fontSize: "13px",
                    width: 50,
                    textAlign: "right",
                  }}
                >
                  {formatMessageTime(item.updated_at)}
                </div>
              </Space>
            </Space>
          }
          description={
            <Typography.Ellipsis style={{ width: 190 }}>
              {item.last_message ? item.last_message : "暂无新消息"}
            </Typography.Ellipsis>
            // <Typography.Ellipsis style={{ width: 190 }}>
            //   {/*{lastMessages[item.thread_id]}*/}
            // </Typography.Ellipsis>
          }
        />
      </List.Item>
    );
  };

  const webChatList = (
    <Space align={"start"}>
      <Space direction={"vertical"}>
        <Space style={{ width: 300 }}>
          <Select
            style={{ width: 70 }}
            defaultValue={chatOption}
            onChange={(value) => setChatOption(value)}
          >
            <Select.Option key={"全部"} value={"全部"}>
              {"全部"}
            </Select.Option>
            <Select.Option key={"新客"} value={"新客"}>
              {"新客"}
            </Select.Option>
            <Select.Option key={"私聊"} value={"私聊"}>
              {"私聊"}
            </Select.Option>
            <Select.Option key={"群聊"} value={"群聊"}>
              {"群聊"}
            </Select.Option>
          </Select>
          <Dropdown droplist={dropList} position="bl" trigger="click">
            <InputSearch
              allowClear
              placeholder="搜索聊天"
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              style={{ width: 187 }}
            />
          </Dropdown>
          <Dropdown
            droplist={
              <Menu style={{ width: 100 }}>
                <Menu.Item key="1" onClick={() => setManageVisible(true)}>
                  管理回复
                </Menu.Item>
                <Menu.Item key="2" onClick={() => setNotificationVisible(true)}>
                  配置通知
                </Menu.Item>
                <Menu.Item key="3" onClick={() => navigate('/addFriends')}>
                  添加好友
                </Menu.Item>
                <Menu.Item key="4" onClick={() => onGroupSendClick()}>
                  群发消息
                </Menu.Item>
              </Menu>
            }
            position="bl"
            trigger="click"
          >
            <Button type="primary" icon={<IconPlus />} />
          </Dropdown>
        </Space>
        {/*聊天列表*/}
        <List
          style={{
            width: 305,
            height: "86vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "#EEE white",
          }}
          dataSource={sortedChatList}
          render={renderListItem}
        />
        {/*回复管理*/}
        <UserManagement
          visible={manageVisible}
          setVisible={setManageVisible}
          wxId={currentWxAccount.wx_id}
          onAutoReplyChange={(updatedUser) => {
            // 更新chatList中对应用户的auto_reply状态
            setChatList((prev) =>
              prev.map((user) =>
                user.to_user_id === updatedUser.to_user_id
                  ? { ...user, auto_reply: updatedUser.auto_reply }
                  : user
              )
            );
          }}
        />
        {/*通知配置*/}
        <NotificationSettingModal
          visible={notificationVisible}
          setVisible={setNotificationVisible}
          wxId={currentWxAccount.wx_id}
        />
      </Space>
      <UserChat
        currentWxUser={currentWxUser}
        currentWxMyAccount={currentWxAccount}
        getMessageList={getMessageList}
        assistant={assistant}
        autoReply={currentAutoReply}
        setAutoReply={setCurrentAutoReply}
        manualMessage={manualList.find(
          (manual) => manual.threadId === currentWxUser?.thread_id
        )}
        fetchUsersWithCheck={fetchUsersWithCheck}
        chatList={chatList}
        setCurrentWxUser={setCurrentWxUser}
      />
    </Space>
  );

  const MobileChatList = (
    <>
      {!showUserChat && (
        <Cell.Group style={{ height: "90vh", overflowY: "auto" }}>
          {sortedChatList.map((item) => (
            <Cell
              key={item.thread_id}
              onClick={() => handleItemClick(item)}
              style={{
                backgroundColor:
                  item.thread_id === currentWxUser?.thread_id
                    ? "#366EF415"
                    : "",
              }}
              icon={
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "9px",
                      height: "9px",
                      borderRadius: "50%",
                      backgroundColor: manualList.some(
                        (manual) => manual.threadId === item.thread_id
                      )
                        ? "#F53F3F"
                        : item.auto_reply
                          ? "#00B42A"
                          : "#FF7D00",
                      position: "absolute",
                      left: -4,
                      top: -4,
                      zIndex: 1,
                    }}
                  />
                  <Avatar>
                    {item.head_url ? (
                      <img alt="头像" src={item.head_url} />
                    ) : (
                      item.nickname.charAt(0)
                    )}
                  </Avatar>
                  {sortedUnreadList[
                    sortedChatList.findIndex(
                      (x) => x.thread_id === item.thread_id
                    )
                  ] > 0 && (
                    <Badge
                      count={
                        sortedUnreadList[
                          sortedChatList.findIndex(
                            (x) => x.thread_id === item.thread_id
                          )
                        ]
                      }
                      style={{
                        position: "absolute",
                        top: -8,
                        right: -8,
                      }}
                    />
                  )}
                </div>
              }
              label={item.remark_name || item.nickname}
              desc={
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "200px",
                  }}
                >
                  {item.last_message || "暂无新消息"}
                </div>
              }
              text={formatMessageTime(item.updated_at)}
            />
          ))}
        </Cell.Group>
      )}
      {showUserChat && (
        <UserChat
          setShowUserChat={setShowUserChat}
          currentWxUser={currentWxUser}
          currentWxMyAccount={currentWxAccount}
          getMessageList={getMessageList}
          assistant={assistant}
          autoReply={currentAutoReply}
          setAutoReply={setCurrentAutoReply}
          manualMessage={manualList.find(
            (manual) => manual.threadId === currentWxUser?.thread_id
          )}
          fetchUsersWithCheck={fetchUsersWithCheck}
          chatList={chatList}
          setCurrentWxUser={setCurrentWxUser}
        />
      )}
    </>
  );

  return <>{isMobile ? MobileChatList : webChatList}</>;
};

export default ChatList;
