// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userReducer from './slices/userSlice'; // 更明确的命名

const rootReducer = combineReducers({
    user: userReducer // 将 userSlice 合并到 'user' 键下
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'] // 确保 whitelist 与 rootReducer 的键名一致
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST']
            }
        })
});

export const persistor = persistStore(store);