// UserChat.jsx
import React, { useEffect, useState, useRef } from "react";
import localforage from "localforage";
import {
  Input,
  Button,
  Tooltip,
  Space,
  Upload,
  Avatar,
  Empty,
  Tag,
  Typography,
  Spin,
  Switch,
  Message,
  Image,
  Popconfirm,
  Card, Modal,
} from "@arco-design/web-react";
import {
  IconCloseCircle,
  IconFolderAdd,
  IconSend,
  IconFile,
} from "@arco-design/web-react/icon";
import {
  getGroupMemberHeadApi,
  sendMessageApi,
  setAutoReplyThread,
  getLarkTemporaryDownloadURLs,
} from "../../../api/normalApi";
import { chatAgainApi } from "../../../api/openaiAPI";
import { formatTimestampToDateTime } from "../../../utils/format";
import axios from "axios";
import { useResponsive } from "../../../utils/useResponsive";
import OpenAI from "openai";
import { NavBar } from "@arco-design/mobile-react";

const openai = new OpenAI({
  baseURL: "https://baseurl.replit.app",
  apiKey: "",
  dangerouslyAllowBrowser: true,
});

const { Text } = Typography;

const UserChat = ({
  currentWxUser,
  currentWxMyAccount,
  getMessageList,
  assistant,
  autoReply,
  setAutoReply,
  manualMessage,
  fetchUsersWithCheck,
  setCurrentWxUser,
  setShowUserChat,
}) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const contentRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [messageImages, setMessageImages] = useState({});
  const [isAIChatting, setIsAIChatting] = useState(false);
  const isFetchingMessages = useRef(false);
  const [messageAvatars, setMessageAvatars] = useState({}); // 新增: 存储消息头像的映射
  const [AIInputValue, setAIInputValue] = useState("");
  const prevMessageLength = useRef(messages.length);
  const [AIReply, setAIReply] = useState("");
  const [AIReplyReason, setAIReplyReason] = useState("");
  const resizeTimeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // 新增：控制加载状态
  const [showNoMessagesHint, setShowNoMessagesHint] = useState(false); // 新增：控制是否显示"暂无消息"
  const [larkImageUrls, setLarkImageUrls] = useState({});
  const [fileCards, setFileCards] = useState({}); // 新增：存储文件卡片的渲染数据
  const { isMobile } = useResponsive();
  const [AIModalVisible, setAIModalVisible] = useState(false)

  // 在 useEffect 中控制加载状态
  useEffect(() => {
    // setIsLoading(true); // 设置加载状态为 true
    setShowNoMessagesHint(false); // 初始化隐藏“暂无消息”提示

    const timer = setTimeout(() => {
      setIsLoading(false); // 加载动画结束
      if (messages.length === 0) {
        setShowNoMessagesHint(true); // 显示“暂无消息”提示
      }
    }, 2000); // 等待 2 秒

    return () => clearTimeout(timer); // 清理计时器
  }, [messages]);

  // 加载图片
  useEffect(() => {
    const loadImages = async () => {
      const newImages = {};
      for (const message of messages) {
        for (const content of message.content) {
          if (content.type === "image_file" && content.image_file?.file_id) {
            if (!messageImages[content.image_file.file_id]) {
              const imageUrl = await getPictureById(content.image_file.file_id);
              if (imageUrl) {
                newImages[content.image_file.file_id] = imageUrl;
              }
            }
          }
        }
      }
      if (Object.keys(newImages).length > 0) {
        setMessageImages((prev) => ({ ...prev, ...newImages }));
      }
    };

    if (messages && messages.length > 0) {
      loadImages();
    }
    // eslint-disable-next-line
  }, [messages]);

  // 处理飞书链接提取和图片下载
  useEffect(() => {
    let newUrls = [];
    messages.forEach((msg) => {
      // 检查是否有content数组且有内容
      if (msg.content && Array.isArray(msg.content) && msg.content.length > 0) {
        msg.content.forEach((contentItem) => {
          if (contentItem.type === "text" && contentItem.text.value) {
            // 使用正则表达式匹配飞书链接并提取fileToken
            const regex =
              /(https:\/\/open\.feishu\.cn\/open-apis\/drive\/v1\/medias\/([^/]+)\/download)/;
            const match = contentItem.text.value.match(regex);
            if (match && match[2]) {
              const fileToken = match[2];
              // console.log("fileToken", fileToken);
              newUrls.push(fileToken);
            }
          }
        });
      }
    });
    if (newUrls.length > 0) {
      handleDownloadPic(newUrls);
    }
  }, [messages]);

  // 处理滚动
  useEffect(() => {
    // 添加滚动事件监听
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", handleScroll);
    }

    // 清理事件监听
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (contentRef.current && !isUserScrolling) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    if (messages && messages.length > 0) {
      modifyMessageRead().then((r) => {});
    }
    // eslint-disable-next-line
  }, [messages]);

  // // 加载聊天
  useEffect(() => {
    setMessages([]);
    const fetchMessages = async () => {
      try {
        if (!currentWxUser) {
          return;
        }

        const messages = await openai.beta.threads.messages.list(
          currentWxUser.thread_id,
          { limit: 100, order: "desc" }
        );

        if (messages && messages.data) {
          setMessages(messages.data);
        }

        const newAvatars = await handleGroupAvatars(
          messages.data,
          currentWxMyAccount,
          currentWxUser,
          messageAvatars
        );

        setMessageAvatars(newAvatars);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages().catch((error) => {
      console.error("Error in fetchMessages:", error);
    });

    // eslint-disable-next-line
  }, [currentWxUser]);

  // 每 5 秒获取当前用户的消息
  useEffect(() => {
    if (currentWxUser) {
      const fetchMessagesWithCheck = async () => {
        isFetchingMessages.current = true;
        // 对当前选中的用户，总是获取100条消息
        const data = await openai.beta.threads.messages.list(
          currentWxUser.thread_id,
          { limit: 100, order: "desc" }
        );
        if (data && data?.data) {
          //
          const latestCurrentMessageId = messages[0]?.id;
          const latestNewMessageId = data.data[0]?.id;

          if (latestCurrentMessageId === latestNewMessageId) {
          } else {
            setMessages(data.data);
          }
        }
      };

      const messageIntervalId = setInterval(fetchMessagesWithCheck, 5000);
      return () => clearInterval(messageIntervalId);
    }
    // eslint-disable-next-line
  }, [currentWxUser]);

  // 处理群聊成员头像的函数
  const handleGroupAvatars = async (
    messages,
    currentWxAccount,
    currentWxUser,
    messageAvatars
  ) => {
    try {
      // 创建新的头像映射对象
      const newMessageAvatars = { ...messageAvatars };

      if (messages) {
        // 遍历所有消息
        for (const message of messages) {
          const actualUserId = message.metadata?.actual_user_id;

          // 检查是否需要获取新的头像
          if (actualUserId && !newMessageAvatars[actualUserId]) {
            // 构建缓存键
            const cacheKey = `avatar-${currentWxAccount.wx_id}-${currentWxUser.to_wx_id}-${actualUserId}`;

            try {
              // 首先尝试从缓存获取
              const cachedAvatar = await localforage.getItem(cacheKey);
              if (cachedAvatar) {
                newMessageAvatars[actualUserId] = cachedAvatar;
                // console.log('从缓存中获取头像:', cachedAvatar);
                continue;
              }

              // 如果缓存中没有，则从API获取
              const response = await getGroupMemberHeadApi(
                currentWxAccount.wx_id,
                currentWxUser.to_wx_id,
                actualUserId
              );

              // 检查API响应
              if (response.data?.head_url) {
                // 获取头像图片数据
                const imageResponse = await axios.get(response.data.head_url, {
                  responseType: "blob",
                });
                const imageBlob = imageResponse.data;

                // 保存到缓存
                await localforage.setItem(cacheKey, imageBlob);

                // 更新头像映射
                newMessageAvatars[actualUserId] = imageBlob;
              }
            } catch (error) {
              console.error("获取头像失败:", error);
            }
          }
        }
      }

      return newMessageAvatars;
    } catch (error) {
      console.error("处理群聊头像时出错:", error);
      return messageAvatars; // 发生错误时返回原始头像映射
    }
  };

  // 滚动事件处理
  const handleScroll = () => {
    setIsUserScrolling(true);
  };

  const modifyMessageRead = async () => {
    const latestMessage = messages.find(
      (item) => item.thread_id === currentWxUser.thread_id
    );
    if (!latestMessage) return;

    const data = {
      metadata: {
        readTime: String(Math.floor(Date.now() / 1000)),
      },
    };
    try {
      await openai.beta.threads.messages.update(
        latestMessage.thread_id,
        latestMessage.id,
        data
      );
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const modifyMessageManual = async () => {
    // 找出所有需要人工处理的消息
    const manualMessages = messages.filter(
      (msg) => msg.metadata?.manual === "true"
    );

    const data = {
      metadata: {
        manual: "false",
      },
    };

    try {
      // 使用Promise.all同时处理所有消息的更新
      const updatePromises = manualMessages.map((msg) =>
        openai.beta.threads.messages.update(msg.thread_id, msg.id, data)
      );

      await Promise.all(updatePromises);

      Message.info("已发送解除人工通知，等待安全校验后自动解除");

      // 更新完成后刷新消息列表
      if (getMessageList) {
        const updatedMessages = await openai.beta.threads.messages.list(
          currentWxUser.thread_id,
          { limit: 100, order: "desc" }
        );
        setMessages(updatedMessages.data);
      }
    } catch (error) {
      console.error("Error updating manual messages:", error);
    }
  };

  const handleSend = async () => {
    if (!inputValue.trim()) return;
    const back_data = {
      thread_id: currentWxUser.thread_id,
      reply: inputValue,
      metadata: {
        manualReply: "true",
        actual_nickname: currentWxMyAccount.nickname,
        actual_user_id: currentWxMyAccount.wx_id,
      }
    };
    setInputValue("");
    try {
      const response = await sendMessageApi(back_data);
      if (response.status === "success") {
        // 直接获取更新后的消息列表
        const threadMessages = await openai.beta.threads.messages.list(
            currentWxUser.thread_id,
            { limit: 100, order: "desc" }
        );
        setMessages(threadMessages.data);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleAISend = async () => {
    setAIModalVisible(false);
    if (!AIInputValue.trim()) return;
    const back_data = {
      thread_id: currentWxUser.thread_id,
      reply: AIInputValue,
      metadata: {
        manualReply: "true",
        actual_nickname: currentWxMyAccount.nickname,
        actual_user_id: currentWxMyAccount.wx_id,
      }
    };
    setAIInputValue("");
    try {
      const response = await sendMessageApi(back_data);
      if (response.status === "success") {
        // 直接获取更新后的消息列表
        const threadMessages = await openai.beta.threads.messages.list(
            currentWxUser.thread_id,
            { limit: 100, order: "desc" }
        );
        setMessages(threadMessages.data);
      }
    } catch (error) {
      console.error("发送消息时出错:", error);
    }
  };

  const handleAutoReplyToggle = (checked) => {
    const autoReplyData = { auto_reply: checked };

    setAutoReplyThread(currentWxUser.id, autoReplyData).then(async (data) => {
      if (data.status === "success") {
        Message.success("自动回复状态更新成功");

        const updatedUser = { ...currentWxUser, auto_reply: checked };
        setCurrentWxUser(updatedUser);
        setAutoReply(checked);

        // 更新 localforage 数据
        await localforage.setItem(
          `${currentWxMyAccount.wx_id}-currentWxUser`,
          updatedUser
        );

        // 更新用户列表
        await fetchUsersWithCheck();
      } else {
        Message.error("自动回复状态更新失败");
      }
    });
  };

  useEffect(() => {
    if (currentWxUser) {
      setAutoReply(currentWxUser.auto_reply);
    }
    // eslint-disable-next-line
  }, [currentWxUser]);

  const chatAIAgain = async () => {
    setIsAIChatting(true);
    // 不要在这里重置 AIInputValue，只有在收到新回复时才更新
    setAIReplyReason(""); // 重置理由

    try {
      const res = await chatAgainApi(currentWxUser.thread_id, assistant.id);

      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        if (res.reply !== null) {
          setAIInputValue(res.reply); // 只在有新回复时更新输入值
          setAIReplyReason(res.reason);
          setAIReply(""); // 清空占位文本
        } else {
          // 保持现有的 AIInputValue，不要清空
          setAIReply("AI建议不回复用户");
          setAIReplyReason(res.reason);
        }
        setIsAIChatting(false);
      }, 100);
    } catch (error) {
      console.error("获取AI建议失败:", error);
      setIsAIChatting(false);
      setAIReply("获取AI建议失败");
      // 保持现有的 AIInputValue，不要清空
      setAIReplyReason(""); // 清空理由
    }
  };

  // 3. 添加清理函数
  useEffect(() => {
    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, []);

  const getPictureById = async (fileId) => {
    try {
      // 先检查缓存
      const cachedBlob = await localforage.getItem(`image-${fileId}`);
      if (cachedBlob) {
        return URL.createObjectURL(cachedBlob);
      }

      const response = await openai.files.content(fileId);
      const blob = await response.blob();
      // 存入缓存
      await localforage.setItem(`image-${fileId}`, blob);
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };

  const checkIfImage = async (url) => {
    try {
      // 发送 HEAD 请求获取文件信息
      const response = await fetch(url, {
        method: 'HEAD',
        redirect: 'follow'
      });
      // 获取 Content-Type
      const contentType = response.headers.get('content-type') || '';

      // 判断是否为图片
      const isImage = ['image/', 'image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp']
        .some(type => contentType.startsWith(type));

      // 获取文件名
      let filename = '';

      // 1. 首先尝试从 Content-Disposition 获取文件名
      const contentDisposition = response.headers.get('content-disposition') || '';

      if (contentDisposition) {
        // 处理 filename* (RFC 5987)
        const filenameStarMatch = contentDisposition.match(/filename\*=([^']*)'[^']*'([^;]*)/);
        if (filenameStarMatch) {
          const encoding = filenameStarMatch[1];
          filename = decodeURIComponent(filenameStarMatch[2]);
        } else {
          // 处理普通 filename
          const filenameMatch = contentDisposition.match(/filename="?([^"]*)"?/);
          if (filenameMatch) {
            filename = filenameMatch[1];
            
            // 处理可能的 URL 编码
            if (filename.includes('%')) {
              filename = decodeURIComponent(filename);
            }

            // 处理引号
            filename = filename.replace(/^["']|["']$/g, '');
          }
        }
      }

      // 2. 如果没有从 Content-Disposition 获取到文件名，从 URL 获取
      if (!filename) {
        filename = url.split('/').pop().split('?')[0];
        filename = decodeURIComponent(filename);
      }

      // 3. 如果还是无法获取到有效的文件名，生成临时文件名
      if (!filename || filename === 'download') {
        const extension = isImage ? '.jpg' : '.file';
        filename = `file_${Date.now()}${extension}`;
      }

      // 4. 确保文件名是合法的
      // 移除不合法的文件名字符
      filename = filename.replace(/[<>:"/\\|?*]/g, '');

      // 5. 如果文件名看起来是 Base64 或其他编码，尝试解码
      if (filename.length > 8 && /^[A-Za-z0-9+/=]+$/.test(filename)) {
        try {
          // 添加必要的填充
          const padding = '='.repeat((4 - filename.length % 4) % 4);
          const decoded = atob(filename + padding);
          // 确保解码结果是可打印的ASCII
          if (/^[\x20-\x7E]*$/.test(decoded)) {
            filename = decoded;
          }
        } catch (error) {
          // 解码失败，保持原文件名
          console.log('Base64 decode failed:', error);
        }
      }

      return {
        isImage,
        filename
      };
    } catch (error) {
      console.error("Error checking file:", error);
      return {
        isImage: false,
        filename: `file_${Date.now()}.file`
      };
    }
  };

  const handleDownloadPic = async (fileTokens) => {
    try {
      // 将单个token转换为数组
      const tokenArray = Array.isArray(fileTokens) ? fileTokens : [fileTokens];
      let newUrls = {};

      // 检查每个token是否有缓存
      for (const token of tokenArray) {
        const cachedUrl = await localforage.getItem(`filetoken-${token}`);
        if (cachedUrl) {
          newUrls[token] = cachedUrl;
        }
      }

      const uncachedTokens = tokenArray.filter((token) => !newUrls[token]);

      if (uncachedTokens.length > 0) {
        const res = await getLarkTemporaryDownloadURLs(uncachedTokens);
        if (res && res.data && res.data.download_urls) {
          const tmpUrls = res.data.download_urls;
          await Promise.all(
            tmpUrls.map(async (item) => {
              try {
                // 先检查是否为图片
                const { isImage, filename } = await checkIfImage(item.tmp_download_url);
                if (!isImage) {
                  // 更新消息内容为文件类型
                  newUrls[item.file_token] = {
                    type: 'file',
                    url: item.tmp_download_url,
                    fileName: filename
                  };
                  return;
                }

                // 如果是图片，继续处理
                const response = await fetch(item.tmp_download_url);
                const blob = await response.blob();

                // Convert to base64
                const reader = new FileReader();
                const base64 = await new Promise((resolve) => {
                  reader.onloadend = () => resolve(reader.result);
                  reader.readAsDataURL(blob);
                });

                // Store base64 using localforage
                await localforage.setItem(
                  `filetoken-${item.file_token}`,
                  base64
                );
                newUrls[item.file_token] = base64;
              } catch (error) {
                console.error("Error converting image to base64:", error);
                // Fallback to storing URL if base64 storage fails
                await localforage.setItem(
                  `filetoken-${item.file_token}`,
                  item.tmp_download_url
                );
                newUrls[item.file_token] = item.tmp_download_url;
              }
            })
          );
        }
      }

      // 更新状态，包含缓存和新获取的URL
      if (Object.keys(newUrls).length > 0) {
        setLarkImageUrls((prevUrls) => ({ ...prevUrls, ...newUrls }));
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      Message.error("下载文件时出错，请稍后再试。");
    }
  };

  // 添加下载文件的函数
  const downloadFile = async (url) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.download = '';  // 添加 download 属性强制下载
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
      Message.error('下载文件失败');
    }
  };

  // 渲染文件卡片的组件
  const FileCard = ({ url }) => (
    <Card
      hoverable
      style={{
        width: 300,
        height: 50,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Space align="center">
        <IconFile style={{fontSize: 30, color: "#8bc34a"}}/>
        <Button
          type="text"
          style={{color: "#165DFF"}}
          onClick={() => downloadFile(url)}
        >
          下载
        </Button>
      </Space>
    </Card>
  );

  const renderMessageContent = (msg, item, contentIndex) => {
    if (item.type === "text" && item.text && item.text.value) {
      // 检查是否是飞书链接
      const regex =
        /(https:\/\/open\.feishu\.cn\/open-apis\/drive\/v1\/medias\/([^/]+)\/download)/;
      const match = item.text.value.match(regex);

      if (match && match[2] && larkImageUrls[match[2]]) {
        const fileLink = match[0];
        const fileToken = match[2];
        const linkIndex = item.text.value.indexOf(fileLink);
        const beforeText = item.text.value.slice(0, linkIndex);
        const afterText = item.text.value.slice(linkIndex + fileLink.length);

        const elements = [];

        // 添加链接前的文字
        if (beforeText.trim()) {
          elements.push(
            <Text
              key="before"
              style={{
                color: msg.role === "user" ? "#000000" : "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: msg.role === "user" ? "#FFFFFF" : "#366EF4",
                display: "inline-block",
                marginRight: "8px",
              }}
            >
              {beforeText}
            </Text>
          );
        }

        // 添加图片或文件卡片
        const urlData = larkImageUrls[fileToken];
        if (urlData && typeof urlData === 'object' && urlData.type === 'file') {
          elements.push(
            <Card
              key="file"
              hoverable
              style={{
                width: 300,
                height: 50,
                display: "flex",
                alignItems: "center",
                margin: "8px 0"
              }}
            >
              <Space align="center">
                <IconFile style={{fontSize: 30, color: "#8bc34a"}}/>
                <Button
                  type="text"
                  style={{color: "#165DFF"}}
                  onClick={() => downloadFile(urlData.url)}
                >
                  下载
                </Button>
              </Space>
            </Card>
          );
        } else {
          elements.push(
            <Image
              key="image"
              src={urlData}
              alt="Lark Image"
              width={300}
              style={{ margin: "8px 0" }}
            />
          );
        }

        // 添加链接后的文字
        if (afterText.trim()) {
          elements.push(
            <Text
              key="after"
              style={{
                color: msg.role === "user" ? "#000000" : "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: msg.role === "user" ? "#FFFFFF" : "#366EF4",
                display: "inline-block",
                marginLeft: "8px",
              }}
            >
              {afterText}
            </Text>
          );
        }

        return (
          <Space
            key={`${msg.id}-${contentIndex}`}
            direction="vertical"
            style={{ width: "100%" }}
          >
            {elements}
          </Space>
        );
      }

      // 用户消息直接显示，不分割
      if (msg.role === "user") {
        return (
          <Space key={`${msg.id}-${contentIndex}`} align="center">
            <Text
              style={{
                color: "#000000",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                display: "inline-block",
                width: "auto",
              }}
            >
              {item.text.value}
            </Text>
          </Space>
        );
      }

      // 检查消息是否包含链接
      const hasLink = /(https?:\/\/[^\s]+)/.test(item.text.value);

      // 如果是销售消息且包含链接，不分割
      if (msg.role === "assistant" && hasLink) {
        return (
          <Space key={`${msg.id}-${contentIndex}`} align="center">
            <Text
              style={{
                color: "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#366EF4",
                display: "inline-block",
                width: "auto",
              }}
            >
              {item.text.value}
            </Text>
          </Space>
        );
      }

      // 其他销售消息保持原有的分割逻辑
      return (
        <React.Fragment key={`${msg.id}-${contentIndex}`}>
          {item.text.value
            .split(/(?<=[？?!。])/)
            .map((sentence, sentenceIndex) => (
              <Space
                key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                align="center"
              >
                <Text
                  style={{
                    color: "#ffffff",
                    padding: "10px",
                    borderRadius: "8px",
                    backgroundColor: "#366EF4",
                    display: "inline-block",
                    width: "auto",
                  }}
                >
                  {sentence.replace(/[？?！!。，,；;、]+$/g, "")}
                </Text>
              </Space>
            ))}
        </React.Fragment>
      );
    } else if (item.type === "image_file" && item.image_file?.file_id) {
      const imageUrl = messageImages[item.image_file.file_id];
      return imageUrl ? (
        <Space key={`${msg.id}-${contentIndex}`}>
          <Image
            src={imageUrl}
            alt="消息图片"
            width={250}
            style={{
              height: "auto",
              borderRadius: "8px",
              margin: "5px 0",
            }}
          />
        </Space>
      ) : (
        <Space key={`${msg.id}-${contentIndex}`}>
          <Spin dot />
        </Space>
      );
    } else if (item.type === "file") {
      const fileCard = fileCards[item.file_token];
      return fileCard ? (
        <Space key={`${msg.id}-${contentIndex}`}>
          <FileCard url={fileCard.url} />
        </Space>
      ) : (
        <Space key={`${msg.id}-${contentIndex}`}>
          <Spin dot />
        </Space>
      );
    } else if (item.type === "image" || (typeof item === 'object' && item.type === 'file')) {
      // 处理图片或文件
      const token = item.file_token || item.token;
      const urlData = larkImageUrls[token];
      
      if (!urlData) {
        handleDownloadPic([token]);
        return (
          <Space key={`${msg.id}-${contentIndex}`}>
            <Spin dot />
          </Space>
        );
      }

      // 如果是文件类型
      if (urlData.type === 'file') {
        return (
          <Card
            key={`${msg.id}-${contentIndex}`}
            hoverable
            style={{
              width: 300,
              height: 50,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Space align="center">
              <IconFile style={{fontSize: 30, color: "#8bc34a"}}/>
              <Button
                type="text"
                style={{color: "#165DFF"}}
                onClick={() => downloadFile(urlData.url)}
              >
                下载
              </Button>
            </Space>
          </Card>
        );
      }

      // 如果是图片类型
      return (
        <Space key={`${msg.id}-${contentIndex}`}>
          <Image
            src={urlData}
            width={200}
            alt="image"
          />
        </Space>
      );
    }
    return null;
  };

  // 助手头像组件
  const AssistantAvatar = ({ currentWxMyAccount }) => {
    return (
      <Avatar shape="square" style={{ backgroundColor: "#3370ff" }} size={33}>
        {currentWxMyAccount.head_url ? (
          <img alt="avatar" src={currentWxMyAccount.head_url} />
        ) : (
          currentWxMyAccount.nickname.charAt(0)
        )}
      </Avatar>
    );
  };

  // 没有选中用户时的提示
  if (!currentWxUser) {
    return (
      <Space
        direction="vertical"
        style={{
          height: "92vh",
          boxShadow: "0px 0 6px rgba(0, 0, 0, 0.08)",
          marginLeft: 5,
        }}
        align={"start"}
      >
        <Empty
          description="请选择一个用户进行聊天"
          style={{ marginTop: "40.8vh", width: "39.5vw" }}
        />
      </Space>
    );
  }

  const WebUserChat = (
    <Space
      direction="vertical"
      style={{
        height: "92vh",
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
        marginLeft: 5,
      }}
      align={"start"}
    >
      <Space
        direction="horizontal"
        style={{
          width: "38vw",
          justifyContent: "space-between",
        }}
      >
        <Space>
          <Avatar
            shape="square"
            style={{ backgroundColor: "#3370ff" }}
            size={33}
          >
            {currentWxUser.head_url ? (
              <img alt="avatar" src={currentWxUser.head_url} />
            ) : (
              currentWxUser.nickname.charAt(0)
            )}
          </Avatar>
          <h3
            style={{
              marginTop: 0,
              marginBottom: 0,
              maxWidth: "12vw", // 这里自定义一个合适的宽度
              whiteSpace: "nowrap", // 不换行
              overflow: "hidden", // 溢出隐藏
              textOverflow: "ellipsis", // 溢出用 ... 省略
            }}
          >
            {currentWxUser.remark_name || currentWxUser.nickname || ""}
          </h3>
          <Switch
            checked={autoReply}
            onChange={handleAutoReplyToggle}
            checkedText="自动回复"
            uncheckedText="人工回复"
          />
          {manualMessage ? (
            <Button
              type="primary"
              status="danger"
              size="mini"
              shape="round"
              onClick={() => modifyMessageManual(manualMessage.message)}
            >
              解除警告
            </Button>
          ) : (
            ""
          )}
        </Space>

        <Space>
          {assistant !== null && assistant.name ? (
            <Avatar
              shape="square"
              style={{ backgroundColor: "#3370ff" }}
              size={33}
            >
              {currentWxMyAccount.head_url ? (
                <img
                  alt="avatar"
                  src={`${currentWxMyAccount.head_url}`}
                />
              ) : (
                currentWxMyAccount.nickname.charAt(0)
              )}
            </Avatar>
          ) : (
            ""
          )}
          <h3 style={{ marginTop: 0, marginBottom: 0, marginLeft: 5 }}>
            {(currentWxMyAccount !== null && currentWxMyAccount.nickname) ||
              "暂未分配数字员工"}
          </h3>
          <Tag
            color={
              assistant !== null && assistant.name
                ? autoReply
                  ? "green"
                  : "orange"
                : "red"
            }
          >
            {assistant !== null && assistant.name
              ? autoReply
                ? "正在服务"
                : "观望不回"
              : "暂未配置"}
          </Tag>
        </Space>
      </Space>
      <Space
        direction="vertical"
        style={{
          background: "#f7f7fa",
          height: "77vh",
          width: "38vw",
          overflowY: "auto",
          scrollbarWidth: "thin",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 10,
          scrollbarColor: "#EEE white",
          boxShadow: "0 2px 2px rgba(0, 0, 0, 0.03)",
        }}
        ref={contentRef}
        onScroll={handleScroll}
      >
        {messages.length === 0 ? (
          <Space
            align="center"
            style={{ width: "36vw", height: "65vh", justifyContent: "center" }}
            direction={"vertical"}
          >
            {isLoading ? (
              <Spin dot /> // 显示加载动画
            ) : showNoMessagesHint ? (
              <Text type="secondary">暂无消息</Text> // 2 秒后显示暂无消息
            ) : (
              messages
                .slice()
                .reverse()
                .map((msg, index) => {
                  return (
                    <Space key={msg.id || index} align={"start"}>
                      {/* 渲染消息内容 */}
                      {msg.content.map((item, contentIndex) =>
                        renderMessageContent(msg, item, contentIndex)
                      )}
                    </Space>
                  );
                })
            )}
          </Space>
        ) : (
          messages
            .slice()
            .reverse()
            .map((msg, index) => {
              return (
                <Space key={msg.id || index} align={"start"}>
                  {msg.role === "user" ? (
                    // <UserAvatar msg={msg} currentWxUser={currentWxUser} />
                    <Avatar
                      shape="square"
                      style={{ backgroundColor: "#3370ff" }}
                      size={33}
                    >
                      {currentWxUser.is_group ? (
                        msg.metadata?.actual_user_id &&
                        messageAvatars[msg.metadata.actual_user_id] ? (
                          <img
                            alt="avatar"
                            // 确保这里传递的是一个有效的 Blob 或 File 对象
                            src={
                              messageAvatars[
                                msg.metadata.actual_user_id
                              ] instanceof Blob
                                ? URL.createObjectURL(
                                    messageAvatars[msg.metadata.actual_user_id]
                                  )
                                : messageAvatars[msg.metadata.actual_user_id]
                            }
                          />
                        ) : (
                          msg.metadata?.actual_nickname?.charAt(0) ||
                          currentWxUser.nickname.charAt(0)
                        )
                      ) : currentWxUser.head_url ? (
                        <img
                          alt="avatar"
                          src={currentWxUser.head_url}
                          onError={(e) => {
                            e.target.style.display = "none";
                          }}
                        />
                      ) : (
                        currentWxUser.nickname.charAt(0)
                      )}
                    </Avatar>
                  ) : (
                    <AssistantAvatar currentWxMyAccount={currentWxMyAccount} />
                  )}
                  <Space direction={"vertical"}>
                    <Space direction="horizontal">
                      <Text>
                        {msg.role === "user"
                          ? currentWxUser.is_group
                            ? messages[messages.length - 1 - index]
                              ? msg.metadata.actual_nickname
                              : ""
                            : currentWxUser.nickname
                          : currentWxMyAccount.nickname}
                      </Text>
                      <Text type="secondary" style={{ fontSize: "12px" }}>
                        {formatTimestampToDateTime(msg.created_at)}
                      </Text>
                      {msg.role === "user" ? (
                        <Tag color={"green"} style={{ color: "#00B42A" }}>
                          客户
                        </Tag>
                      ) : (
                        <Tag color={"orange"} style={{ color: "#FF7D00" }}>
                          销售
                        </Tag>
                      )}
                      {msg.metadata?.manual === "true" ? (
                        <Tag color={"red"} style={{ color: "#FF0000" }}>
                          触发人工
                        </Tag>
                      ) : msg.metadata?.manual === "false" ? (
                        <Tag color={"green"} style={{ color: "#00B42A" }}>
                          人工解除
                        </Tag>
                      ) : (
                        ""
                      )}
                      {msg.metadata?.manualReply === "true" ? (
                        <Tag color={"orange"} style={{ color: "#FF7D00" }}>
                          人工回复
                        </Tag>
                      ) : (
                        ""
                      )}
                    </Space>
                    <Space direction={"vertical"}>
                      {msg.content.map((item, contentIndex) =>
                        renderMessageContent(msg, item, contentIndex)
                      )}
                    </Space>
                  </Space>
                </Space>
              );
            })
        )}
      </Space>
      <Space style={{ marginTop: 10, marginLeft: "1.5vw" }}>
        <Tooltip content="选择文件发送">
          <Upload
            action={null}
            showUploadList={false}
            // fileList={pic ? [pic] : []}
            multiple={false}
            // onChange={(_, currentFile) => handleUploadPic(_, currentFile)}
            accept=".png,.jpeg,.jpg"
          >
            <IconFolderAdd style={{ width: "20px", height: "20px" }} />
          </Upload>
        </Tooltip>
        <Input
          value={inputValue}
          suffix={
            <Button
                type="text"
                onClick={() => {
                  setAIModalVisible(true);
                  chatAIAgain();
                }}
            >
              AI生成
            </Button>
          }
          onChange={(value) => setInputValue(value)}
          onPressEnter={handleSend}
          style={{
            borderRadius: "3vh",
            height: "5vh",
            width: "31.5vw",
            marginLeft: "0.5vw",
            marginRight: "0.5vw",
          }}
        />
        <Tooltip content="发送消息">
          <Button
            type="text"
            icon={<IconSend style={{ width: "20px", height: "20px" }} />}
            onClick={handleSend}
            disabled={inputValue.trim() === ""}
          />
        </Tooltip>
      </Space>
      <Modal
          visible={AIModalVisible}
          title="AI建议"
          onOk={AIInputValue ? handleAISend : () => setAIModalVisible(false)}
          onCancel={() => {
            setAIModalVisible(false);
            setAIInputValue(""); // 关闭时清空输入值
            setAIReply(""); // 关闭时清空占位文本
            setAIReplyReason(""); // 关闭时清空理由
          }}
          autoFocus={false}
          okText={AIInputValue ? "发送" : "确定"}
          cancelText="重试"
          maskClosable={false}
          style={{ width: 'auto', minWidth: '500px' }}
          footer={
            <Space>
              <Button
                  onClick={() => {
                    setAIModalVisible(false);
                    setAIInputValue(""); // 关闭时清空输入值
                    setAIReply(""); // 关闭时清空占位文本
                    setAIReplyReason(""); // 关闭时清空理由
                  }}
              >
                取消
              </Button>
              <Button onClick={chatAIAgain}>
                重试
              </Button>
              <Button
                  type="primary"
                  onClick={handleAISend}
                  disabled={!AIInputValue}
              >
                发送
              </Button>
            </Space>
          }
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {isAIChatting ? (
              <Spin dot style={{ width: "100%" }} />
          ) : (
              <>
                <Input.TextArea
                    value={AIInputValue}
                    onChange={(value) => setAIInputValue(value)}
                    placeholder={AIReply}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                />
                <div>
                  <div style={{ marginBottom: 8 }}>理由:</div>
                  <Input.TextArea
                      value={AIReplyReason}
                      autoSize={{ minRows: 2, maxRows: 4 }}
                      readOnly
                  />
                </div>
              </>
          )}
        </Space>
      </Modal>

    </Space>
  );

  const MobileUserChat = (
    <div style={{ height: "95vh", display: "flex", flexDirection: "column" }}>
      <NavBar
        fixed={false}
        title={currentWxUser?.nickname || "聊天"}
        hasBottomLine={false}
        onClickLeft={() => setShowUserChat(false)}
        style={{ backgroundColor: "#fff" }}
      />
      <div
        ref={contentRef}
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "10px",
          backgroundColor: "#F7F8FA",
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spin dot />
          </div>
        ) : showNoMessagesHint ? (
          <Empty description="暂无消息" style={{ marginTop: "40vh" }} />
        ) : (
          messages
            .slice()
            .reverse()
            .map((msg, index) => (
              <div
                key={msg.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: msg.role === "user" ? "flex-start" : "flex-end",
                  marginBottom: "20px",
                }}
              >
                <Space
                  align="start"
                  style={{
                    flexDirection: msg.role === "user" ? "row" : "row-reverse",
                  }}
                >
                  {msg.role === "user" ? (
                    // <UserAvatar msg={msg} currentWxUser={currentWxUser} />
                    <Avatar
                      shape="square"
                      style={{ backgroundColor: "#3370ff" }}
                      size={33}
                    >
                      {currentWxUser.is_group ? (
                        msg.metadata?.actual_user_id &&
                        messageAvatars[msg.metadata.actual_user_id] ? (
                          <img
                            alt="avatar"
                            // 确保这里传递的是一个有效的 Blob 或 File 对象
                            src={
                              messageAvatars[
                                msg.metadata.actual_user_id
                              ] instanceof Blob
                                ? URL.createObjectURL(
                                    messageAvatars[msg.metadata.actual_user_id]
                                  )
                                : messageAvatars[msg.metadata.actual_user_id]
                            }
                          />
                        ) : (
                          msg.metadata?.actual_nickname?.charAt(0) ||
                          currentWxUser.nickname.charAt(0)
                        )
                      ) : currentWxUser.head_url ? (
                        <img
                          alt="avatar"
                          src={currentWxUser.head_url}
                          onError={(e) => {
                            e.target.style.display = "none";
                          }}
                        />
                      ) : (
                        currentWxUser.nickname.charAt(0)
                      )}
                    </Avatar>
                  ) : (
                    <AssistantAvatar currentWxMyAccount={currentWxMyAccount} />
                  )}
                  <Space
                    direction="vertical"
                    style={{
                      maxWidth: "70vw",
                      marginLeft: msg.role === "user" ? "8px" : 0,
                      marginRight: msg.role === "assistant" ? "8px" : 0,
                    }}
                  >
                    {msg.content.map((item, contentIndex) =>
                      renderMessageContent(msg, item, contentIndex)
                    )}
                  </Space>
                </Space>
              </div>
            ))
        )}
      </div>
      <Space
        direction="vertical"
        style={{ width: "100vw", backgroundColor: "#fff" }}
      >
        <Space
          style={{
            width: "85%",
            padding: "20px 10px",
            justifyContent: "space-between",
            marginLeft: "5vw",
          }}
        >
          <Upload
            action={null}
            showUploadList={false}
            multiple={false}
            accept=".png,.jpeg,.jpg"
          >
            <IconFolderAdd style={{ width: "20px", height: "20px" }} />
          </Upload>
          <Input
            value={inputValue}
            onChange={setInputValue}
            placeholder="请输入消息"
            style={{
              flex: 1,
              margin: "0 10px",
              width: "70vw",
              height: "5vh",
            }}
          />
          <Button
            type="text"
            icon={<IconSend style={{ width: "20px", height: "20px" }} />}
            onClick={handleSend}
            disabled={inputValue.trim() === ""}
          />
        </Space>
      </Space>
    </div>
  );

  return isMobile ? MobileUserChat : WebUserChat;
};

export default UserChat;
