import React from "react";
import PropTypes from "prop-types";
import { LarkTable } from "./components/LarkTable";

// const TabPane = Tabs.TabPane;

const SalesMaterial = ({
  productColumns,
  caseColumns,
  productTableStyle,
  caseTableStyle,
}) => {
  return (
      <LarkTable></LarkTable>
    // <Tabs defaultActiveTab="feishu">
    //   <TabPane key="feishu" title="飞书列表">
    //     <LarkTable></LarkTable>
    //   </TabPane>
    //   {/*<TabPane key="products" title="原商品列表">*/}
    //   {/*  <ProductList columns={productColumns} tableStyle={productTableStyle} />*/}
    //   {/*</TabPane>*/}
    //   {/*<TabPane key="cases" title="原案例列表">*/}
    //   {/*  <CaseMaterialTable columns={caseColumns} tableStyle={caseTableStyle} />*/}
    //   {/*</TabPane>*/}
    // </Tabs>
  );
};

SalesMaterial.propTypes = {
  productColumns: PropTypes.arrayOf(PropTypes.string),
  caseColumns: PropTypes.arrayOf(PropTypes.string),
  productTableStyle: PropTypes.object,
  caseTableStyle: PropTypes.object,
};

export default SalesMaterial;
