import React, { useEffect, useMemo, useState } from 'react';
import { Table, Typography, Spin, Button, Space, Message, Collapse, Checkbox } from '@arco-design/web-react';
import { useSelector } from 'react-redux';
import { checkUserTable, getTableData, updateAssistantApi } from '../../../../../api/normalApi';
import { useNavigate } from 'react-router-dom';
import OpenAI from "openai";
import { processLarkFiles } from "../../../../../api/openaiAPI";

const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});

const SalesData = ({ assistant, setTableData }) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.currentUser);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [checkedTables, setCheckedTables] = useState([]);
    const [needsUpdate, setNeedsUpdate] = useState(false);
    const [activeKeys, setActiveKeys] = useState([]);

    // tableInfos 计算保持不变
    const tableInfos = useMemo(() => {
        if (!data || Object.keys(data).length === 0) return { names: [], tableIds: {} };
        return {
            names: Object.keys(data),
            tableIds: Object.keys(data).reduce((acc, tableName) => {
                acc[tableName] = data[tableName].table_id;
                return acc;
            }, {})
        };
    }, [data]);


    useEffect(() => {
        if (assistant) {
            try {
                const knowledgeTables = assistant.knowledge_tables
                    ? JSON.parse(assistant.knowledge_tables)
                    : [];
                setCheckedTables(knowledgeTables);
            } catch (error) {
                console.error("Invalid JSON in assistant.knowledge_tables:", error);
                // Fallback to empty array if JSON is invalid
                setCheckedTables([]);
            }
        }
    }, [assistant]);

    const extractTableData = (tableData) => ({
        商品列表: tableData?.商品列表 || [],
        案例列表: tableData?.案例列表 || []
    });

    const extractTable = (tableData, tableName) => ({
        [tableName]: tableData?.[tableName] || []
    })

    const extractFieldValue = (field) => {
        if (!field) return '';

        // 处理数组类型
        if (Array.isArray(field)) {
            // 特殊处理附件数组
            if (field[0]?.url || field[0]?.file_token) {
                return field.map(item => item.url || '').join('\n');
            }
            // 处理普通文本数组
            return field.map(item => item.text || '').join(' ');
        }

        // 处理数字类型
        if (typeof field === 'number') {
            return String(field);
        }

        // 处理对象类型
        if (typeof field === 'object') {
            if (field.link) return field.link;
            if (field.text) return field.text;
        }

        return String(field || '');
    };

    const formatRecord = (record) => {
        const result = [];

        for (const [key, value] of Object.entries(record)) {
            // 跳过 RecordId
            if (key === 'RecordId') continue;

            const extractedValue = extractFieldValue(value);
            if (extractedValue) {
                result.push(`${key}: ${extractedValue}`);
            }
        }

        return result.join('\n');
    };


    // 更新云端提示
    const updateSalesSummary = async (tableData, tableItems) => {
        let salesSummary = '';
        try {
            // 检查选中的表格中是否包含商品列表和案例列表
            const hasProductList = tableItems.some(item => item.tableName === '商品列表');
            const hasCaseList = tableItems.some(item => item.tableName === '案例列表');
            const otherTables = tableItems.filter(item => 
                item.tableName !== '商品列表' && item.tableName !== '案例列表'
            );

            // 处理商品列表
            if (hasProductList) {
                const { 商品列表 } = extractTable(tableData, '商品列表');
                if (商品列表 && 商品列表.records && 商品列表.records.length > 0) {
                    const productInstructions = 商品列表.records.map(formatRecord);
                    salesSummary += `我们的产品信息如下，（附件是图片或者文件链接可以直接发送，一次最多发3张）:\n${productInstructions}\n\n`;
                }
            }

            // 处理案例列表
            if (hasCaseList) {
                const { 案例列表 } = extractTable(tableData, '案例列表');
                if (案例列表 && 案例列表.records && 案例列表.records.length > 0) {
                    const casesInstructions = 案例列表.records
                        .filter((item) => item.案例名称)
                        .map((item) => {
                            const caseName = item.案例名称[0]?.text || "无名称";
                            const caseType = item.类型 || "内部检索使用";
                            const caseLink = item.链接?.link || "无链接";
                            const caseFile = item.附件?.[0]?.url || "";
                            const caseDescription = item.介绍 && item.介绍[0]?.text ? item.介绍[0].text : null;
                            const descriptionPart = caseDescription ? ` 描述：${caseDescription}` : "";

                            // 如果类型是"内部检索使用"，则不包含链接和文件信息
                            if (caseType === "内部检索使用") {
                                return `名称：${caseName} 类型：${caseType}${descriptionPart}`;
                            }

                            // 对于其他类型，包含链接或文件信息
                            if (caseFile) {
                                return `名称：${caseName} 类型：${caseType}${descriptionPart} 文件链接：${caseFile}`;
                            }
                            return `名称：${caseName} 类型：${caseType}${descriptionPart} 链接：${caseLink}`;
                        })
                        .join("\n");

                    if (casesInstructions.trim() !== "") {
                        salesSummary += `服务的参考资料名称、类型、介绍、文件/图片附件链接如下：\n${casesInstructions}\n\n`;
                    }
                }
            }

            // 处理其他表格
            for (const table of otherTables) {
                const tableDescription = await getTableDescription(tableData,table.tableName);
                if (tableDescription) {
                    salesSummary += `${table.tableName}的信息如下：\n${tableDescription}\n\n`;
                }
            }

            // 添加防御性检查
            if (!assistant) {
                console.warn('Assistant is not available');
                return salesSummary;
            }

            const newEmployee = {
                sales_summary: salesSummary,
                knowledge_tables: JSON.stringify(tableItems)
            };

            // 添加防御性检查
            if (
                assistant.sales_summary === salesSummary &&
                assistant.knowledge_tables === JSON.stringify(tableItems)
            ) {
                return salesSummary;
            }

            console.log(newEmployee);
            
            if(assistant.sales_summary === salesSummary && assistant.knowledge_tables === JSON.stringify(tableItems)){
                return salesSummary;
            }

            await updateAssistantApi(assistant.id, newEmployee);

            return salesSummary;
        } catch (error) {
            console.error('Error updating sales summary:', error);
            Message.error('更新销售提示失败');
            return '';
        }
    };


    // 获取表格描述
    const getTableDescription = async (tableData, tableName) => {
        try {
            const table = tableData[tableName];
            if (!table?.columns || !table?.records) {
                return '';
            }

            const formatFieldValue = (value) => {
                if (!value) return '';

                const formatObject = (obj) => {
                    if (obj.text) return obj.text;
                    if (obj.link) return obj.link;
                    if (obj.url) return obj.url;

                    const values = Object.entries(obj)
                        .filter(([key, val]) =>
                            val &&
                            typeof val !== 'object' &&
                            key !== 'id' &&
                            key !== 'file_token'
                        )
                        .map(([_, val]) => val);

                    return values.join('/');
                };

                if (Array.isArray(value)) {
                    return value.map(item => {
                        if (typeof item === 'object') {
                            return formatObject(item);
                        }
                        return item;
                    }).filter(Boolean).join(', ');
                }
                if (typeof value === 'object') {
                    return formatObject(value);
                }
                return String(value);
            };

            const fields = table.columns.filter(col => col !== 'RecordId');
            const records = table.records.map(record => {
                const fieldValues = fields.map(field => {
                    const value = formatFieldValue(record[field]);
                    return value ? `${field}：${value}` : null;
                })
                    .filter(Boolean)
                    .join('，');
                return fieldValues ? `（${fieldValues}）` : null;
            }).filter(Boolean).join('；\n');

            const description = `${tableName}包含以下字段：${fields.join('、')}。`;
            return records ? `${description}\n\n记录如下：\n${records}` : description;
        } catch (error) {
            console.error('Error generating table description:', error);
            return '';
        }
    };

    // 修改初始数据加载的 useEffect
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                let tableUrl = user?.table_url;

                if (!tableUrl) {
                    const response = await checkUserTable(user.user_id);
                    if (response?.data?.table_url) {
                        tableUrl = response.data.table_url;
                    } else {
                        console.error("table_url not found in response");
                        setLoading(false);
                        return;
                    }
                }

                const tableResponse = await getTableData(tableUrl);
                if (tableResponse?.status === 'success' && tableResponse?.data) {
                    setData(tableResponse.data);
                    setTableData(tableResponse.data);

                    // 设置默认选中的表格
                    const defaultTables = [];
                    const initialActiveKeys = [];

                    Object.entries(tableResponse.data).forEach(([tableName, tableData]) => {
                        if (tableName === '商品列表' || tableName === '案例列表') {
                            defaultTables.push({
                                tableName: tableName,
                                tableId: tableData.table_id
                            });
                            initialActiveKeys.push(tableName);
                        }
                    });

                    // 立即设置选中状态
                    setCheckedTables(defaultTables);
                    setActiveKeys(initialActiveKeys);
                    setLoading(false);
                    // 触发数据库更新
                    await updateSalesSummary(tableResponse.data, defaultTables);
                    // 更新选中表格的向量存储
                    for (const table of defaultTables) {
                        await updateTableVStore(tableResponse.data, table.tableName);
                    }

                    await updateVStore(tableResponse.data);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setLoading(false);
            }
        };

        fetchInitialData();
    }, [user, setTableData]);

    // 移除或修改之前的设置默认值的 useEffect
    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const defaultTables = [];
            const initialActiveKeys = [];

            Object.entries(data).forEach(([tableName, tableData]) => {
                if (tableName === '商品列表' || tableName === '案例列表') {
                    defaultTables.push({
                        tableName: tableName,
                        tableId: tableData.table_id
                    });
                    initialActiveKeys.push(tableName);
                }
            });

            // 只在没有选中表格时设置默认值
            if (checkedTables.length === 0) {
                setCheckedTables(defaultTables);
                setActiveKeys(initialActiveKeys);
                setNeedsUpdate(true);
            }
        }
    }, [data]);

    // 处理表格选中状态变更
    useEffect(() => {
        const updateData = async () => {
            if (needsUpdate && Object.keys(data).length > 0) {
                try {
                    await updateSalesSummary(data, checkedTables);
                    // 更新选中表格的向量存储
                    for (const table of checkedTables) {
                        await updateTableVStore(data, table.tableName);
                    }
                    setNeedsUpdate(false);
                } catch (error) {
                    console.error("Error updating data:", error);
                }
            }
        };

        updateData();
    }, [checkedTables, data, needsUpdate]);

    // 表格勾选处理函数
    const handleTableCheck = (checked, listName, tableId) => {
        setCheckedTables(prev => {
            const newCheckedTables = checked
                ? [...prev, { tableName: listName, tableId }]
                : prev.filter(item => item.tableId !== tableId);
            setNeedsUpdate(true);
            return newCheckedTables;
        });

        // 同步更新展开状态
        setActiveKeys(prev => {
            // 确保 prev 是数组
            const prevArray = Array.isArray(prev) ? prev : [];
            if (checked) {
                return [...prevArray, listName];
            } else {
                return prevArray.filter(key => key !== listName);
            }
        });
    };

    const handleCollapseChange = (keys) => {
        console.log('Collapse onChange:', keys); // 调试用
        setActiveKeys(keys); // Arco Design 的 Collapse 组件会确保传入的是数组
    };

    // 辅助函数：判断文件是否为图片类型
    const isImageFileType = (extension) => {
        const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.svg'];
        return imageExtensions.includes(extension.toLowerCase());
    };

    const updateVStore = async (data) => {
        try {
            if (!data.案例列表 || !data.案例列表.records) {
                console.warn('案例列表或记录缺失');
                return;
            }

            if (assistant) {
                const vectorStoreFiles = await openai.beta.vectorStores.files.list(
                    assistant.openai_vs_id
                );

                // 筛选有附件的案例
                const casesWithAttachments = data.案例列表.records.filter(
                    (record) => record['附件'] && record['附件'].length > 0
                );

                if (casesWithAttachments.length > 0) {
                    // 处理附件文件
                    const fileTokens = casesWithAttachments
                        .map((item) => item.附件
                            .map((file) => ({
                                token: file.file_token,
                                name: file.name,
                                ext: getFileExtension(file.name)
                            }))
                        )
                        .flat();

                    const nonImageFileTokens = fileTokens.filter(f => !isImageFileType(f.ext));

                    if (nonImageFileTokens.length > 0) {
                        const result = await processLarkFiles(nonImageFileTokens.map(f => f.token));

                        if (result.result) {
                            // 处理向量库文件
                            const existingVStoreFileIds = new Set(vectorStoreFiles.data.map(file => file.id));

                            const processedFiles = result.data
                                .filter(item => item.success && item.status === 'completed')
                                .map(item => {
                                    const fileInfo = nonImageFileTokens.find(f => f.token === item.lark_file_id);
                                    return {
                                        id: item.openai_file_id,
                                        originalName: fileInfo?.name,
                                        extension: fileInfo?.ext
                                    };
                                })
                                .filter(file => isSupportedFileType(file.extension));

                            const filesToRemove = [...existingVStoreFileIds]
                                .filter(fileId => !processedFiles.some(f => f.id === fileId));

                            const filesToAdd = processedFiles
                                .filter(file => !existingVStoreFileIds.has(file.id));

                            // 删除多余文件
                            for (const fileId of filesToRemove) {
                                try {
                                    console.log(`Removing file from vector store: ${fileId}`);
                                    await openai.beta.vectorStores.files.del(
                                        assistant.openai_vs_id,
                                        fileId
                                    );
                                } catch (error) {
                                    console.error(`Error removing file ${fileId}:`, error);
                                }
                            }

                            // 添加新文件
                            for (const file of filesToAdd) {
                                try {
                                    if (isSupportedFileType(file.extension)) {
                                        console.log(`Adding file to vector store: ${file.id} (${file.originalName})`);
                                        await openai.beta.vectorStores.files.create(
                                            assistant.openai_vs_id,
                                            { file_id: file.id }
                                        );
                                    } else {
                                        console.log(`Skipping unsupported file: ${file.originalName}`);
                                    }
                                } catch (error) {
                                    console.error(`Error adding file ${file.id}:`, error);
                                    Message.error(`添加文件失败: ${file.originalName}`);
                                }
                            }

                            if (filesToAdd.length > 0 || filesToRemove.length > 0) {
                                Message.success(`文件知识库同步完成 (添加: ${filesToAdd.length}, 删除: ${filesToRemove.length})`);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error updating vector store:', error);
            Message.error("更新向量库失败，请稍后重试");
        }
    };

    const updateTableVStore = async (data, tableName) => {
        try {
            if (!data[tableName] || !data[tableName].records) {
                console.warn(`${tableName}缺失`);
                return;
            }

            if (assistant) {
                const vectorStoreFiles = await openai.beta.vectorStores.files.list(
                    assistant.openai_vs_id
                );

                const recordsWithLinks = data[tableName].records.filter(record => {
                    return Object.entries(record).some(([key, value]) => {
                        const isArray = Array.isArray(value);
                        if (!isArray) return false;
                        return value.some(item =>
                            item && typeof item === 'object' && 'file_token' in item
                        );
                    });
                });


                // 提取所有文件token
                const fileTokens = recordsWithLinks.flatMap(record => {
                    return Object.entries(record)
                        .filter(([_, value]) => Array.isArray(value) && value.some(item => item && typeof item === 'object' && 'file_token' in item))
                        .flatMap(([columnName, value]) =>
                            value
                                .filter(item => item && typeof item === 'object' && 'file_token' in item)
                                .map(item => ({
                                    token: item.file_token,
                                    name: item.name || '',
                                    ext: getFileExtension(item.name)
                                }))
                        );
                });

                if (fileTokens.length > 0) {
                    // console.log(`找到${fileTokens.length}个文件:`, fileTokens);

                    const nonImageFileTokens = fileTokens.filter(f => !isImageFileType(f.ext));

                    if (nonImageFileTokens.length > 0) {
                        const result = await processLarkFiles(nonImageFileTokens.map(f => f.token));

                        if (result.result) {
                            // 处理向量库文件
                            const existingVStoreFileIds = new Set(vectorStoreFiles.data.map(file => file.id));

                            const processedFiles = result.data
                                .filter(item => item.success && item.status === 'completed')
                                .map(item => {
                                    const fileInfo = nonImageFileTokens.find(f => f.token === item.lark_file_id);
                                    return {
                                        id: item.openai_file_id,
                                        originalName: fileInfo?.name,
                                        extension: fileInfo?.ext
                                    };
                                })
                                .filter(file => isSupportedFileType(file.extension));

                            const filesToRemove = [...existingVStoreFileIds]
                                .filter(fileId => !processedFiles.some(f => f.id === fileId));

                            const filesToAdd = processedFiles
                                .filter(file => !existingVStoreFileIds.has(file.id));

                            // 删除多余文件
                            for (const fileId of filesToRemove) {
                                try {
                                    console.log(`Removing file from vector store: ${fileId}`);
                                    await openai.beta.vectorStores.files.del(
                                        assistant.openai_vs_id,
                                        fileId
                                    );
                                } catch (error) {
                                    console.error(`Error removing file ${fileId}:`, error);
                                }
                            }

                            // 添加新文件
                            for (const file of filesToAdd) {
                                try {
                                    if (isSupportedFileType(file.extension)) {
                                        console.log(`Adding file to vector store: ${file.id} (${file.originalName})`);
                                        await openai.beta.vectorStores.files.create(
                                            assistant.openai_vs_id,
                                            { file_id: file.id }
                                        );
                                    } else {
                                        console.log(`Skipping unsupported file: ${file.originalName}`);
                                    }
                                } catch (error) {
                                    console.error(`Error adding file ${file.id}:`, error);
                                    Message.error(`添加文件失败: ${file.originalName}`);
                                }
                            }

                            if (filesToAdd.length > 0 || filesToRemove.length > 0) {
                                Message.success(`文件知识库同步完成 (添加: ${filesToAdd.length}, 删除: ${filesToRemove.length})`);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error updating vector store:', error);
            Message.error("更新向量库失败，请稍后重试");
        }
    };

    // 辅助函数：获取文件扩展名
    const getFileExtension = (filename) => {
        if (!filename) return '';
        const ext = filename.split('.').pop().toLowerCase();
        return ext ? `.${ext}` : '';
    };

    // 辅助函数：检查文件类型是否支持
    const isSupportedFileType = (extension) => {
        const supportedExtensions = [
            '.txt', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx',
            '.csv', '.json'
        ];
        return supportedExtensions.includes(extension?.toLowerCase());
    };


    const generateColumns = (columns) =>
        columns.map((col) => {
            const needEditButton = col === '商品名称' || col === '案例名称';

            return {
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography.Text style={{ marginRight: 8 }}>{col}</Typography.Text>
                        {needEditButton && (
                            <Button
                                type="text"
                                onClick={() => navigate(`/files/${assistant.id}`)}
                            >
                                编辑
                            </Button>
                        )}
                    </div>
                ),
                dataIndex: col,
                render: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item, idx) => (
                            <Typography.Text key={idx} style={{ display: 'block' }}>
                                {item.text}
                            </Typography.Text>
                        ));
                    } else if (value?.link) {
                        return (
                            <Typography.Text target="_blank">
                                {value.text}
                            </Typography.Text>
                        );
                    }
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography.Text style={{ marginRight: 8 }}>
                                {value || '-'}
                            </Typography.Text>
                        </div>
                    );
                },
            };
        });


    return (
        <Spin loading={loading}>
            <Space direction="vertical" style={{ marginTop: 5, height: '75vh', overflow: 'auto' }}>
                <Collapse
                    destroyOnHide
                    accordion={false}  // 允许同时展开多个
                    activeKey={activeKeys}
                    onChange={handleCollapseChange}
                    style={{
                        maxHeight: '74vh',
                        overflow: 'auto'
                    }}
                >
                    {tableInfos.names.map((tableName) => {
                        const list = data[tableName];
                        const tableId = list?.table_id;
                        if (!list || !list.records) {
                            return null;
                        }

                        let filteredColumns = [];
                        if (tableName === '商品列表') {
                            filteredColumns = list.columns.filter((col) => col === '商品名称' || col === '价格');
                        } else if (tableName === '案例列表') {
                            filteredColumns = list.columns.filter((col) => col === '案例名称');
                        } else {
                            filteredColumns = list.columns;
                        }

                        const filteredRecords = tableName === '商品列表'
                            ? list.records.filter(record => record['商品名称'])
                            : tableName === '案例列表'
                                ? list.records.filter(record => record['案例名称'])
                                : list.records;

                        const columns = generateColumns(filteredColumns);

                        return (
                            <Collapse.Item
                                key={tableName}
                                header={tableName}
                                name={tableName}
                                extra={
                                    <Checkbox
                                        checked={checkedTables.some(item => item.tableId === tableId)}
                                        onChange={(checked) => handleTableCheck(checked, tableName, tableId)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                }
                            >
                                <Table
                                    columns={columns}
                                    data={filteredRecords}
                                    rowKey="RecordId"
                                    pagination={{ pageSize: 5 }}
                                    scroll={{ x: true, y: 300 }}
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                />
                            </Collapse.Item>
                        );
                    })}
                </Collapse>
            </Space>
        </Spin>
    );
};

export default SalesData;