import React, { useEffect, useState } from "react";
import ChatList from "./Messages/ChatList";
import AssistantInfo from "./AssistantInfo/AssistantInfo";
import { Avatar, Space } from "@arco-design/web-react";
import { DropdownMenu } from "@arco-design/mobile-react"; // 设置别名为 MobileTabs
import { Tabs as WebTabs } from "@arco-design/web-react"; // 设置别名为 WebTabs
import { getAssistantByWxIDApi, getWeChats } from "../../api/normalApi";
import { useParams } from "react-router-dom";
import GroupSendMessage from "./GroupMessage/GroupSendMessage";
import { useResponsive } from "../../utils/useResponsive";

const Messages = () => {
  const [wxAccountList, setWxAccountList] = useState([]);
  const [currentWxAccount, setCurrentWxAccount] = useState(null);
  const [currentAssistant, setCurrentAssistant] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const { wx_id: urlWxId, to_wx_id: urlToWxId } = useParams();
  const [toWxId, setToWxId] = useState(null);
  const [showGroupSendMessage, setShowGroupSendMessage] = useState(false);
  const { isMobile } = useResponsive();

  // Fetch WeChat accounts
  const fetchAccounts = async () => {
    try {
      const weChatsResponse = await getWeChats();
      // console.log("WeChats Response:", weChatsResponse);

      if (
        weChatsResponse.status === "success" ||
        weChatsResponse.status === 200
      ) {
        const storedData = getStoredCurrentWxAccount();
        setWxAccountList(weChatsResponse.data);

        if (weChatsResponse.data.length > 0) {
          let targetAccount;

          if (urlWxId) {
            // 如果URL中有wx_id，优先使用它
            targetAccount = weChatsResponse.data.find(
              (account) => account.wx_id === urlWxId
            );
          }
          if (!targetAccount) {
            // 如果URL中没有wx_id或找不到对应账号，使用存储的账号或默认第一个
            targetAccount =
              (storedData &&
                weChatsResponse.data.find(
                  (account) => account.wx_id === storedData.wx_id
                )) ||
              weChatsResponse.data[0];
          }

          if (urlToWxId) {
            setToWxId(urlToWxId);
          }

          setCurrentWxAccount(targetAccount);
          storeCurrentWxAccount(targetAccount);
          await fetchAssistant(targetAccount.wx_id);
        }
      } else {
        console.error("Unexpected API response:", weChatsResponse);
      }
    } catch (error) {
      console.error("Error fetching WeChat accounts:", error.message);
    }
  };

  // Fetch assistant info by wx_id
  const fetchAssistant = async (wx_id) => {
    try {
      const response = await getAssistantByWxIDApi(wx_id);
      // console.log("Assistant Response:", response);

      if (response.status === "success" && response.data) {
        setCurrentAssistant(response.data);
      } else {
        setCurrentAssistant(null);
      }
    } catch (error) {
      console.error("Error fetching assistant:", error.message);
      setCurrentAssistant(null);
    }
  };

  // Handle tab change
  const handleTabChange = async (wx_id) => {
    const foundAccount = wxAccountList.find(
      (account) => account.wx_id === wx_id
    );

    if (foundAccount) {
      setCurrentWxAccount(foundAccount);
      storeCurrentWxAccount(foundAccount);
      await fetchAssistant(foundAccount.wx_id);
    } else {
      console.warn("Selected account not found in wxAccountList:", wx_id);
      setCurrentAssistant(null);
    }
  };

  // Utility: Get current WxAccount from localStorage
  const getStoredCurrentWxAccount = () => {
    try {
      const data = localStorage.getItem("currentWxAccount");
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error("Error parsing stored data:", error.message);
      localStorage.removeItem("currentWxAccount"); // Clean invalid data
      return null;
    }
  };

  // Utility: Store current WxAccount in localStorage
  const storeCurrentWxAccount = (account) => {
    try {
      localStorage.setItem("currentWxAccount", JSON.stringify(account));
    } catch (error) {
      console.error("Error storing data to localStorage:", error.message);
    }
  };

  // Fetch WeChat accounts on component mount
  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line
  }, []);

  const [showUserChat, setShowUserChat] = useState(false);

  return (
    <div>
      {isMobile ? (
        <div>
          {!showUserChat && (
            <DropdownMenu
              options={wxAccountList.map((account, index) => ({
                value: account.wx_id,
                label: account.nickname,
              }))}
              defaultValue={currentWxAccount?.wx_id}
              onOptionClick={async (value) => {
                const selectedAccount = wxAccountList.find(
                  (account) => account.wx_id === value
                );
                if (selectedAccount) {
                  setCurrentWxAccount(selectedAccount);
                  storeCurrentWxAccount(selectedAccount);
                  await fetchAssistant(selectedAccount.wx_id);
                }
              }}
            />
          )}
          {currentWxAccount && (
            <ChatList
              currentWxAccount={currentWxAccount}
              setThreadId={setThreadId}
              assistant={currentAssistant}
              toWxId={toWxId}
              showUserChat={showUserChat}
              setShowUserChat={setShowUserChat}
              onGroupSendClick={() => setShowGroupSendMessage(true)}
            />
          )}
        </div>
      ) : (
        <WebTabs
          activeTab={currentWxAccount ? currentWxAccount.wx_id : null}
          onChange={handleTabChange}
          type="text"
          style={{ marginTop: 8, width: "87vw" }}
        >
          {wxAccountList.map((account) => (
            <WebTabs.TabPane
              key={account.wx_id}
              title={
                <Space>
                  <Avatar
                    shape="square"
                    size={30}
                    style={{ backgroundColor: "#3370ff" }}
                  >
                    {account.head_url ? (
                      <img alt="头像" src={account.head_url} />
                    ) : (
                      account.nickname.charAt(0)
                    )}
                  </Avatar>
                  {account.nickname}
                </Space>
              }
            >
              {currentWxAccount?.wx_id === account.wx_id && (
                <Space align="start">
                  {showGroupSendMessage ? (
                    currentAssistant && (
                      <GroupSendMessage
                        currentAssistant={currentAssistant}
                        currentWxAccount={currentWxAccount}
                        setShowGroupSendMessage={setShowGroupSendMessage}
                      />
                    )
                  ) : (
                    <div>
                      <ChatList
                        currentWxAccount={currentWxAccount}
                        setThreadId={setThreadId}
                        assistant={currentAssistant}
                        toWxId={toWxId}
                        setShowUserChat={setShowUserChat}
                        onGroupSendClick={() => setShowGroupSendMessage(true)}
                      />
                      {currentAssistant && (
                        <AssistantInfo
                          assistant={currentAssistant}
                          threadId={threadId}
                        />
                      )}
                    </div>
                  )}
                </Space>
              )}
            </WebTabs.TabPane>
          ))}
        </WebTabs>
      )}
      {/*{wxAccountList.length === 0 && (*/}
      {/*    <Space align={'start'} direction={"vertical"}>*/}
      {/*        <h3>暂无消息，绑定微信后显示</h3>*/}
      {/*        <Space>*/}
      {/*            <Timeline style={{width: "200px", marginTop: 20}}>*/}
      {/*                <TimelineItem>1.点击员工列表</TimelineItem>*/}
      {/*                <TimelineItem>2.点击编辑按钮</TimelineItem>*/}
      {/*                <TimelineItem>3.点击发布按钮</TimelineItem>*/}
      {/*                <TimelineItem>4.点击添加帐号</TimelineItem>*/}
      {/*                <TimelineItem>5.扫码登录微信</TimelineItem>*/}
      {/*                <TimelineItem>6.点击发布更新</TimelineItem>*/}
      {/*            </Timeline>*/}
      {/*            <Space style={{width: "80vw"}} direction={"vertical"}>*/}
      {/*                <Image*/}
      {/*                    height={200}*/}
      {/*                    src='https://picx.zhimg.com/70/v2-afa6618afa2a8a91a2209b8bd510c132_1440w.avis'*/}
      {/*                    alt='lamp'*/}
      {/*                />*/}
      {/*                <Image*/}
      {/*                    height={200}*/}
      {/*                    src='https://picx.zhimg.com/70/v2-159ac0ee6795938b5540dbae4079e131_1440w.avis'*/}
      {/*                    alt='lamp'*/}
      {/*                />*/}
      {/*               <Space>*/}
      {/*                   <Image*/}
      {/*                       height={250}*/}
      {/*                       src='https://pic1.zhimg.com/70/v2-78a03c879734331fb0847d7a545319ac_1440w.avis'*/}
      {/*                       alt='lamp'*/}
      {/*                   />*/}
      {/*                   <Image*/}
      {/*                       height={250}*/}
      {/*                       src='https://picx.zhimg.com/70/v2-a746461726475d569a7fc600e16bde4b_1440w.avis'*/}
      {/*                       alt='lamp'*/}
      {/*                   />*/}
      {/*               </Space>*/}
      {/*            </Space>*/}
      {/*        </Space>*/}
      {/*    </Space>*/}
      {/*)}*/}
    </div>
  );
};

export default Messages;
