import React, {useState, useMemo, useEffect} from 'react';
import {Upload, Space, Typography, Message, Spin, Modal, Checkbox, Card, Button} from '@arco-design/web-react';
import {IconEye, IconDelete, IconArrowUp, IconToTop} from '@arco-design/web-react/icon';
import COS from 'cos-js-sdk-v5';
import {getCOSToken, createImageApi, getImageByUserIdApi} from "../../../../../api/normalApi";
import {selectCurrentUser} from '../../../../../store/slices/userSlice';
import {useSelector} from "react-redux";

const LearnChat = ({assistant}) => {
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedImages, setSelectedImages] = useState(new Set());
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        if (user?.user_id) {
            getImageByUserIdApi(user.user_id).then((res) => {
                if (res.status === 'success' && res.data) {
                    const formattedFiles = res.data.map(item => ({
                        uid: item.id,
                        name: item.oss_url.split('/').pop(),
                        url: `https://${item.oss_url}`,
                        status: 'done'
                    }));
                    setFileList(formattedFiles);
                }
            });
        }
    }, [user]);

    const handleImageSelect = (uid) => {
        setSelectedImages(prev => {
            const newSet = new Set(prev);
            if (newSet.has(uid)) {
                newSet.delete(uid);
            } else {
                newSet.add(uid);
            }
            return newSet;
        });
    };

    useEffect(() => {
        console.log('selectedImages changed:', selectedImages);
    }, [selectedImages])

    // 渲染上传列表
    const renderUploadList = (filesList, props) => (
        <div style={{display: 'flex', marginTop: 20, flexWrap: 'wrap', gap: 10}}>
            {filesList.map((file) => {
                const url = file.url || URL.createObjectURL(file.originFile);
                return (
                    <div
                        key={file.uid}
                        style={{
                            position: 'relative',
                            width: 140,
                            height: 140,
                        }}
                    >
                        <img
                            src={url}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: 4,
                            }}
                            alt=""
                        />
                        <div style={{
                            position: 'absolute',
                            top: 8,
                            left: 8,
                        }}>
                            <Checkbox
                                checked={selectedImages.has(file.uid)}
                                onChange={() => handleImageSelect(file.uid)}
                            />
                        </div>
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: 32,
                            background: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                        }}>
                            <IconEye
                                style={{
                                    fontSize: 16,
                                    color: '#fff',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    Modal.info({
                                        title: '预览',
                                        content: <img src={url} style={{width: '100%'}} alt=""/>,
                                    });
                                }}
                            />
                            <IconDelete
                                style={{
                                    fontSize: 16,
                                    color: '#fff',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    props.onRemove(file);
                                    setSelectedImages(prev => {
                                        const newSet = new Set(prev);
                                        newSet.delete(file.uid);
                                        return newSet;
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );

    // 初始化 COS 实例
    const cos = useMemo(
        () =>
            new COS({
                getAuthorization: function (options, callback) {
                    // 这里需要替换成你的获取 COS 凭证的 API
                    getCOSToken()
                        .then((response) => {
                            if (response.status !== "success") {
                                Message.error("获取上传凭证失败");
                                return;
                            }

                            const data = response.data;
                            const currentTime = Math.floor(Date.now() / 1000);

                            callback({
                                TmpSecretId: data.temp_key,
                                TmpSecretKey: data.temp_secret,
                                SecurityToken: data.token,
                                StartTime: currentTime - 60,
                                ExpiredTime: data.expiration,
                                ScopeLimit: true,
                            });
                        })
                        .catch((error) => {
                            console.error("Error fetching COS token:", error);
                            Message.error("获取上传凭证失败");
                        });
                },
            }),
        []
    );

    // 文件类型验证
    const validateFile = (file) => {
        const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

        if (!acceptedTypes.includes(file.type)) {
            Message.error('只能上传图片文件(JPG/PNG/GIF/WEBP)');
            return false;
        }

        const maxSize = 10 * 1024 * 1024; // 10MB
        if (file.size > maxSize) {
            Message.error('图片大小不能超过10MB');
            return false;
        }

        return true;
    };

    // 上传到 COS
    const uploadToCOS = async (file, key) => {
        try {
            const res = await getCOSToken();

            if (!res.data?.bucket || !res.data?.region) {
                new Error('获取上传凭证失败');
            }

            return new Promise((resolve, reject) => {
                cos.uploadFile(
                    {
                        Bucket: res.data.bucket,
                        Region: res.data.region,
                        Key: key,
                        Body: file,
                    },
                    function (err, data) {
                        if (err) {
                            console.error("上传失败:", err);
                            reject(err);
                        } else {
                            console.log("上传成功:", data);
                            resolve(data);
                        }
                    }
                );
            });
        } catch (error) {
            console.error("COS上传失败:", error);
            throw error;
        }
    };

    const beforeUpload = async (file) => {
        if (!validateFile(file)) {
            return false;
        }

        setLoading(true);

        try {
            // 生成文件路径
            const fileName = `${user?.user_id || 'anonymous'}/${Date.now()}_${file.name}`;

            // 先展示本地预览
            const reader = new FileReader();
            reader.onload = async () => {
                const base64 = reader.result;
                const newFile = {
                    ...file,
                    url: base64,
                    status: 'uploading',
                    uid: Date.now().toString(),
                };

                setFileList(prev => [...prev, newFile]);

                try {
                    // 上传到 COS
                    const result = await uploadToCOS(file, fileName);
                    await createImage(result.Location)
                    // 更新文件状态
                    setFileList(prev =>
                        prev.map(f =>
                            f.uid === newFile.uid
                                ? {...f, status: 'done', cosUrl: fileName}
                                : f
                        )
                    );

                    Message.success('上传成功');
                } catch (error) {
                    Message.error('上传失败：' + error.message);
                    // 更新文件状态为错误
                    setFileList(prev =>
                        prev.map(f =>
                            f.uid === newFile.uid
                                ? {...f, status: 'error'}
                                : f
                        )
                    );
                }
            };
            reader.readAsDataURL(file);
        } catch (error) {
            Message.error('处理失败：' + error.message);
        } finally {
            setLoading(false);
        }

        return false; // 阻止默认上传
    };

    const createImage = async (oss_url) => {
        const back_data = {
            oss_url: oss_url,
            assistant_id: assistant.id,
            user_id: user.user_id
        }
        const res = await createImageApi(back_data)
    }

    return (
        <Spin loading={loading} style={{width: '100%'}}>
            <Space direction="vertical" style={{width: '100%'}}>
                <Space
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16}}>
                    <Upload
                        multiple
                        fileList={fileList}
                        beforeUpload={beforeUpload}
                        accept=".jpg,.jpeg,.png,.gif,.webp"
                        onChange={(fileList) => {
                            setFileList(fileList);
                        }}
                        renderUploadList={renderUploadList}
                    >
                        <Space>
                            <Button type='primary' icon={<IconToTop/>}>
                                上传聊天记录长截图给AI学习
                            </Button>
                        </Space>
                    </Upload>
                </Space>

                <Button type={"primary"}>
                    开始分析
                </Button>
            </Space>

        </Spin>
    );
};

export default LearnChat;