// AssistantInfo.js
import React, {useEffect, useState} from "react";
import {Space, Radio, Input, Message, Button} from "@arco-design/web-react";
import {
    getThreadBackgroundApi,
    putThreadBackgroundApi,
} from "../../../api/normalApi";
import {LarkTable} from "../../SalesMaterial/components/LarkTable";
import UploadRecordModal from "../Modal/UploadRecordModal";
import {useParams, useNavigate} from "react-router-dom"; // 引入 useNavigate

const TextArea = Input.TextArea;

const AssistantInfo = ({assistant, threadId}) => {
    const [materialTab, setMaterialTab] = useState("聊天记忆");
    const [roleInfo, setRoleInfo] = useState();
    const [background, setBackground] = useState();
    const [addBackground, setAddBackground] = useState('');
    // 从url获取参数
    const {to_wx_id} = useParams();
    const navigate = useNavigate(); // 初始化 navigate

    // 修改当前线程背景信息
    const updateThreadBackground = () => {
        if (background) {
            putThreadBackgroundApi(threadId, {background: background}).then((res) => {
                if (res && res.status === "success") {
                    Message.success("修改用户画像成功！");
                } else {
                    console.log(res);
                    Message.error("修改用户画像失败！");
                }
            });
        }
    };

    useEffect(() => {
        if (threadId) {
            getThreadBackgroundApi(threadId).then((res) => {
                if (res && res.status === "success") {
                    setBackground(res.data.background);
                }
            });
        }
    }, [threadId]);

    useEffect(() => {
        if (assistant && assistant.role_instructions) {
            setRoleInfo(assistant.role_instructions);
        }
    }, [assistant]);

    useEffect(() => {
        if (addBackground) {
            setBackground((prevBackground) => (prevBackground ? prevBackground + "\n" + addBackground : addBackground));
        }

        // eslint-disable-next-line
    }, [addBackground]);

    return (
        <Space direction="vertical" style={{marginTop: 1, marginLeft: 10}}>
            <Space>
                <Radio.Group
                    type="button"
                    name="tab"
                    value={materialTab}
                    onChange={setMaterialTab}
                    options={["聊天记忆", "员工信息", "销售资料"]}
                >
                </Radio.Group>
                {
                    // 如果有 to_wx_id
                    to_wx_id && (
                        <Button
                            type="primary" // 添加类型以增强按钮的可见性
                            onClick={() => navigate("/userManage")} // 使用 navigate 进行路由跳转
                        >
                            返回客户管理
                        </Button>
                    )
                }
                <UploadRecordModal setAddBackground={setAddBackground}/>
            </Space>
            {materialTab === "聊天记忆" && (
                <TextArea
                    value={background}
                    onChange={(v) => setBackground(v)}
                    onBlur={updateThreadBackground}
                    style={{
                        width: "28vw",
                        height: "87vh",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                    }}
                ></TextArea>
            )}
            {materialTab === "员工信息" && (
                <TextArea
                    value={roleInfo}
                    style={{
                        width: "28vw",
                        height: "87vh",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                    }}
                ></TextArea>
            )}
            {materialTab === "销售资料" && (
                <LarkTable width={"30vw"} customHeight={800} employeeId={assistant.id}/>
            )}
        </Space>
    );
};

export default AssistantInfo;