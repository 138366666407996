// src/api/normalApi.js
import {Message} from "@arco-design/web-react";
import {getToken} from '../utils/auth'; // 导入新的 getToken 函数

const chatServiceUrl = "https://chat.zhihua.chat/shop";
let modelServiceUrl = "https://zchat-model-service-1-eric166188.replit.app";
const addServiceUrl = "https://zchat-add-time-116838-7-1320884641.sh.run.tcloudbase.com";
let wxCloudServiceUrl = "https://zchat-bg-go-1-116838-7-1320884641.sh.run.tcloudbase.com";

const isLocal =
    (window.location.hostname === "localhost" ||
        window.location.hostname === "127.0.0.1") &&
    document.title === "智话销售";

if (isLocal) {
    wxCloudServiceUrl = "http://localhost:80";
    modelServiceUrl = "http://localhost:8000";
}

async function fetchTokenApi(url, options = {}) {
    try {
        const token = getToken();
        if (token) {
            options.headers = {
                ...(options.headers || {}),
                Authorization: token,
            };
        } else {
            window.location.href = "/login";
            console.warn("未登录需要登录");
            return;
        }

        if (!options.headers["Content-Type"]) {
            options.headers["Content-Type"] = "application/json";
        }

        // 确保 body 被正确转换为字符串
        if (options.body && typeof options.body === "object") {
            options.body = JSON.stringify(options.body);
        }

        const response = await fetch(`${url}`, options);
        if (response.status === 401) {
            window.location.href = "/login";
            console.warn("无权限");
            return;
        }
        if (response.status !== 200) {
            console.error("Error fetching API", response);
            Message.error(`API请求错误: ${response.statusText}`);
        }
        return response.json();
    } catch (error) {
        console.error("Network response was not ok", error);
        Message.error("网络错误，请稍后重试");
    }
}

// export async function loginWechatApi() {
//     //登陆微信
//     return fetchTokenApi(`${chatServiceUrl}/login_wechat`, { method: "POST" });
// }

// 获取登录二维码
export async function getWechatQRCodeApi(appId, regionId) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/qrcode`, {
        method: "POST",
        body: JSON.stringify({
            appId,
            regionId
        })
    });
}

// 检查登录状态
export async function checkWechatLoginApi(uuid, appid) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/check_login?uuid=${uuid}&appid=${appid}`, {method: "GET"});
}


export async function logoutWechatApi(data) {
    //登出微信
    return fetchTokenApi(`${chatServiceUrl}/login_out`, {
        method: "POST",
        body: data,
    });
}

export async function cancelWechatApi(data) {
    //取消登陆微信
    return fetchTokenApi(`${chatServiceUrl}/cancel_login`, {
        method: "POST",
        body: data,
    });
}

export async function bindAgentApi(data) {
    //微信绑定agent
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants/bind_agent`, {
        method: "POST",
        body: data,
    });
}

export async function unBindAgentApi(data) {
    //微信解绑agent
    return fetchTokenApi(`${chatServiceUrl}/unbind_agent`, {
        method: "POST",
        body: data,
    });
}

export async function getWxAccountsApi(need_head = true) {
    //获取所有微信账号
    return fetchTokenApi(
        `${chatServiceUrl}/get_wx_accounts?need_head=${need_head}`,
        {method: "GET"},
    );
}

export async function getWxChatsApi(wxId) {
    //获取微信助手的消息
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/wx/${wxId}`, {
        method: "GET",
    });
}

export async function distributeAddFriends(params) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechat-accounts/distribute-and-calculate`, {
        method: "POST",
        body: JSON.stringify(params)
    });
}

export async function startAddFriends() {
    return fetchTokenApi(`${addServiceUrl}/api/v1/contact-add-task/start`, {
        method: "POST",
    });
}

export async function checkAddFriends() {
    return fetchTokenApi(`${addServiceUrl}/api/v1/contact-add-task/status`, {
        method: "GET",
    });
}

export async function updateAddWords(userId, addWords) {
    return fetchTokenApi(`${wxCloudServiceUrl}/users/${userId}/add-words`, {
        method: "PUT",  // 不是 POST，应该用 PUT 方法
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            add_words: addWords
        })
    });
}


// 获取群聊中每个账号的头像
export async function getGroupMemberHeadApi(
    wx_user_id,
    to_user_id,
    actual_user_id,
) {
    //微信自定义发送消息
    return fetchTokenApi(
        `${chatServiceUrl}/get_group_member_head?wx_user_id=${wx_user_id}&to_user_id=${to_user_id}&actual_user_id=${actual_user_id}`,
        {
            method: "GET",
        },
    );
}

export async function setGroupApi(data) {
    //微信自定义发送消息
    return fetchTokenApi(`${wxCloudServiceUrl}/set_groups`, {
        method: "POST",
        body: data,
    });
}

export async function getContactsApi(wxId) {
    //微信自定义发送消息
    return fetchTokenApi(`${chatServiceUrl}/get_contacts?wx_user_id=${wxId}`, {
        method: "GET",
    });
}

export async function setAutoReplyForUser(data) {
    //微信绑定agent
    return fetchTokenApi(`${chatServiceUrl}/set_auto_reply_for_user`, {
        method: "POST",
        body: data,
    });
}

export async function setAutoReplyThread(threadId, data) {
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/${threadId}/auto_reply`, {
        method: "PUT",
        body: data, // fetchTokenApi 内部会自动转换为 JSON 字符串
    });
}

export async function getWxUsersApi(wxAccount, need_head = true) {
    //获取微信助手的消息
    return fetchTokenApi(
        `${chatServiceUrl}/get_wx_users?wx_user_id=${wxAccount}&need_head=${need_head}`,
        {method: "GET"},
    );
}

export async function sendMessageApi(data) {
    return fetchTokenApi(`${modelServiceUrl}/api/chat/send_message`, {
        method: "POST",
        body: {
            thread_id: data.thread_id,
            reply: data.reply,
            metadata: data.metadata
        },
    });
}


export async function login(username, password) {
    return fetch(`${wxCloudServiceUrl}/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            username,
            password,
        }),
    }).then((response) => {
        if (response.status !== 200) {
            console.log("Error fetching API", response);
            Message.info("用户名或密码错误");
        }
        return response.json();
    });
}

export async function getUserData(user_id) {
    // 获取用户数据
    const url = `${wxCloudServiceUrl}/users/${user_id}`; // 将 user_id 插入到 URL 中
    return fetchTokenApi(url, {method: "GET"});
}

export async function checkUserTable(user_id) {
    // 获取用户数据
    const url = `${wxCloudServiceUrl}/users/table/${user_id}`; // 将 user_id 插入到 URL 中
    return fetchTokenApi(url, {method: "GET"});
}

export async function getTableData(table_url) {
    // 获取用户数据
    const url = `${wxCloudServiceUrl}/lark/table?token_or_url=${table_url}`; // 将 user_id 插入到 URL 中
    return fetchTokenApi(url, {method: "GET"});
}

export async function getTableFieldsApi(table_url) {
    // 获取表格字段
    const url = `${wxCloudServiceUrl}/lark/table/fields?token_or_url=${table_url}`; // 将 user_id 插入到 URL 中
    return fetchTokenApi(url, {method: "GET"});
}

export async function updateTableOptionsApi(table_url, table_id, field_id, data) {
    // 获取表格字段
    const url = `${wxCloudServiceUrl}/lark/table/options?token_or_url=${table_url}&field_id=${field_id}&table_id=${table_id}`;
    return fetchTokenApi(url, {method: "PUT", body: data});
}

export async function addProductApi(data) {
    //新增商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products`, {
        method: "POST",
        body: data,
    });
}

export async function getProductsApi() {
    //获取商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products`, {method: "GET"});
}

export async function updateProductApi(id, data) {
    //修改商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products?id=${id}`, {
        method: "PUT",
        body: data,
    });
}

export async function deleteProductApi(id) {
    //删除商品
    return fetchTokenApi(`${wxCloudServiceUrl}/products?id=${id}`, {
        method: "DELETE",
    });
}

export async function addCaseApi(data) {
    //新增案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases`, {
        method: "POST",
        body: data,
    });
}

export async function getCasesApi() {
    //获取案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases`, {method: "GET"});
}

export async function updateCaseApi(id, data) {
    //修改案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases?id=${id}`, {
        method: "PUT",
        body: data,
    });
}

export async function deleteCaseApi(id) {
    //删除案例
    return fetchTokenApi(`${wxCloudServiceUrl}/cases?id=${id}`, {
        method: "DELETE",
    });
}

export async function addAssistantApi(data) {
    //新增员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants`, {
        method: "POST",
        body: data,
    });
}

export async function getAssistantsApi() {
    //获取员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants`, {method: "GET"});
}

export async function getAssistantsIdApi(assistants_id) {
    //获取员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants/${assistants_id}`, {
        method: "GET",
    });
}

export async function updateAssistantApi(id, data) {
    //修改员工
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants?id=${id}`, {
        method: "PUT",
        body: data,
    });
}

export async function deleteAssistantApi(id) {
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants/${id}`, {
        method: "DELETE",
    });
}

// 新增接口：获取员工及其微信账号
export async function getAssistantsWithWeChatApi() {
    return fetchTokenApi(`${wxCloudServiceUrl}/assistants/wechat`, {
        method: "GET",
    });
}

// 新增接口：更新微信账号的自动回复状态
export async function updateWechatAutoReplyApi(data) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/update`, {
        method: "PUT",
        body: data,
    });
}

export async function getWeChats() {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats`, {method: "GET"});
}

export async function updateWeChatsWxId(appid) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/update_wx_id?appid=${appid}`, {method: "PUT"});
}


export async function getWeChatByID(uuid) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/detail/${uuid}`, {method: "GET"})
}

export async function getWeChatByUserID(page, pageSize) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechat-accounts?page=${page}&page_size=${pageSize}`, {method: "GET"})
}

export async function createWeChatByUserID(data) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechat-accounts/batch`, {method: "POST", body: data})
}

export async function updateWeChatByUserID(data) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechat-accounts/batch`, {method: "PUT", body: data})
}

export async function delWeChatByUserID(data) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechat-accounts/batch`, {method: "DELETE", body: data})
}

// 获取助手关联的商品
export async function getAssistantProductsApi(assistant_id) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/products-assistant-bindings/assistant/${assistant_id}`,
        {method: "GET"},
    );
}

export async function updateBroadcastPrompt(wx_id, prompt) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/broadcast_prompt?wx_id=${wx_id}`, {
        method: "PUT",
        body: {prompt},
    });
}

export async function getBroadcastPrompt(wx_id) {
    return fetchTokenApi(`${wxCloudServiceUrl}/wechats/broadcast_prompt/${wx_id}`, {
        method: "GET",
    });
}

// 获取助手关联的案例
export async function getAssistantCasesApi(assistant_id) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/case-assistant-bindings/assistant/${assistant_id}`,
        {method: "GET"},
    );
}

//
export async function getAssistantByWxIDApi(wx_id) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/wechats/assistant?wx_id=${wx_id}`,
        {method: "GET"},
    );
}

// 批量删除案例与助手绑定关系
export async function delCaseAssistantByIdApi(data) {
    return fetchTokenApi(`${wxCloudServiceUrl}/case-assistant-bindings/batch`, {
        method: "DELETE",
        body: data,
    });
}

// 批量删除商品与助手绑定关系
export async function delProductAssistantByIdApi(data) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/products-assistant-bindings/batch`,
        {method: "DELETE", body: data},
    );
}

// 批量新增案例与助手绑定关系
export async function addCaseAssistantApi(data) {
    return fetchTokenApi(`${wxCloudServiceUrl}/case-assistant-bindings/batch`, {
        method: "POST",
        body: data,
    });
}

// 批量更新案例与助手绑定关系
export async function updateCaseAssistantBindingsApi(data) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/case-assistant-bindings/update-bindings`,
        {
            method: "PUT",
            body: data,
        },
    );
}

// 批量新增商品与助手绑定关系
export async function addProductAssistantApi(data) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/products-assistant-bindings/batch`,
        {method: "POST", body: data},
    );
}

export async function getThreadBackgroundApi(id) {
    //获取当前聊天的背景信息
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/${id}`, {method: "GET"});
}

export async function putThreadBackgroundApi(id, data) {
    //获取当前聊天的背景信息
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/by_thread_id/${id}`, {
        method: "PUT",
        body: data,
    });
}

// 获取群组列表
export async function getGroupThreadsApi(wxId) {
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/groups/${wxId}`, {
        method: "GET",
    });
}

// 获取当前通知群组
export async function getNotificationThreadApi(wxId) {
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/notification/${wxId}`, {
        method: "GET",
    });
}

// 更新通知状态
export async function updateThreadNotificationApi(threadId, isNotification) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/threads/${threadId}/notification`,
        {
            method: "PUT",
            body: {
                is_notification: isNotification,
            },
        },
    );
}

// 获取cos临时上传token
export async function getCOSToken() {
    return fetchTokenApi(`${wxCloudServiceUrl}/cos/upload-signature`, {
        method: "GET",
    });
}

// 获取下载的token
export async function getCOSDownLoadTokenApi(objUrl) {
    return fetchTokenApi(
        `${wxCloudServiceUrl}/cos/download/${encodeURIComponent(objUrl)}`,
        {
            method: "GET",
        },
    );
}

// 获取临时密钥的函数，调用STS API
export async function getTempCredentials() {
    const response = await fetch(
        `https://sts.tencentcloudapi.com/?Action=GetFederationToken&Version=2018-08-13&DurationSeconds=1800&Region=ap-guangzhou&Name=test&Policy=${encodeURIComponent(
            JSON.stringify({
                version: "2.0",
                statement: [
                    {
                        action: ["name/cos:PutObject"],
                        effect: "allow",
                        resource: [
                            "qcs::cos:ap-guangzhou:1256349444/zcloud-1256349444/*", // 设置你具体的存储桶
                        ],
                    },
                ],
            }),
        )}`,
        {
            method: "GET",
        },
    );
    const data = await response.json();
    console.log(data);
    return data.Response.Credentials;
}

export async function addMessageApi(data) {
    //新增消息
    return fetchTokenApi(`${wxCloudServiceUrl}/messages`, {
        method: "POST",
        body: data,
    });
}

export async function getMessagesByConversationApi(conversationId) {
    // 获取对话的所有消息
    return fetchTokenApi(
        `${wxCloudServiceUrl}/messages/conversation?conversation_id=${conversationId}`,
        {
            method: "GET",
        },
    );
}

export async function getMessageByIdApi(id) {
    // 根据ID获取消息
    return fetchTokenApi(`${wxCloudServiceUrl}/messages/${id}`, {
        method: "GET",
    });
}

export async function createMessageApi(data) {
    // 创建新消息
    return fetchTokenApi(`${wxCloudServiceUrl}/messages`, {
        method: "POST",
        body: data,
    });
}

export async function updateMessageApi(id, data) {
    // 更新消息
    return fetchTokenApi(`${wxCloudServiceUrl}/messages/${id}`, {
        method: "PUT",
        body: data,
    });
}

export async function deleteMessageApi(id) {
    // 删除消息
    return fetchTokenApi(`${wxCloudServiceUrl}/messages/${id}`, {
        method: "DELETE",
    });
}

export async function getConversationsApi() {
    // 获取用户的所有对话
    return fetchTokenApi(`${wxCloudServiceUrl}/conversations`, {
        method: "GET",
    });
}

export async function getConversationByIdApi(id) {
    // 根据ID获取对话
    return fetchTokenApi(`${wxCloudServiceUrl}/conversations/${id}`, {
        method: "GET",
    });
}

export async function getConversationsByThreadIdApi(threadId) {
    // 根据线程ID获取对话列表
    return fetchTokenApi(
        `${wxCloudServiceUrl}/conversations/thread/${threadId}`,
        {
            method: "GET",
        },
    );
}

export async function createConversationApi(data) {
    // 创建新对话
    return fetchTokenApi(`${wxCloudServiceUrl}/conversations`, {
        method: "POST",
        body: data,
    });
}

export async function updateConversationApi(id, data) {
    // 更新对话
    return fetchTokenApi(`${wxCloudServiceUrl}/conversations/${id}`, {
        method: "PUT",
        body: data,
    });
}

export async function deleteConversationApi(id) {
    // 删除对话
    return fetchTokenApi(`${wxCloudServiceUrl}/conversations/${id}`, {
        method: "DELETE",
    });
}

export async function getChartsByConversationApi(conversationId) {
    // 获取对话的所有图表
    return fetchTokenApi(
        `${wxCloudServiceUrl}/conversation_charts/conversation/${conversationId}`,
        {
            method: "GET",
        },
    );
}

export async function getChartsByMessageApi(messageId) {
    // 获取消息的所有图表
    return fetchTokenApi(
        `${wxCloudServiceUrl}/conversation_charts/message/${messageId}`,
        {
            method: "GET",
        },
    );
}

export async function getChartByIdApi(id) {
    // 根据ID获取图表
    return fetchTokenApi(`${wxCloudServiceUrl}/conversation_charts/${id}`, {
        method: "GET",
    });
}

export async function createChartApi(data) {
    // 创建新图表
    return fetchTokenApi(`${wxCloudServiceUrl}/conversation_charts`, {
        method: "POST",
        body: data,
    });
}

export async function updateChartApi(id, data) {
    // 更新图表
    return fetchTokenApi(`${wxCloudServiceUrl}/conversation_charts/${id}`, {
        method: "PUT",
        body: data,
    });
}

export async function deleteChartApi(id) {
    // 删除图表
    return fetchTokenApi(`${wxCloudServiceUrl}/conversation_charts/${id}`, {
        method: "DELETE",
    });
}

export async function getWxThreadsApi(wxId) {
    // 获取单个微信的线程
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/wx/${wxId}`, {
        method: "GET",
    });
}

export async function getWxThreadsByTypeApi(wx_ids, page, page_size, is_group) {
    // 获取多个微信的线程
    return fetchTokenApi(`${wxCloudServiceUrl}/threads/by_wx_ids?wx_ids=${wx_ids}&page=${page}&limit=${page_size}&is_group=${is_group}`, {
        method: "GET",
    });
}

export async function getLarkTemporaryDownloadURLs(fileTokens) {
    try {
        const params = new URLSearchParams();
        fileTokens.forEach(token => params.append('file_tokens', token));

        return fetchTokenApi(`${wxCloudServiceUrl}/lark/media/download_urls?${params.toString()}`, {
            method: "GET",
        });
    } catch (error) {
        console.error("Error fetching temporary download URLs:", error);
        throw error;
    }
}

export async function createImageApi(data) {
    // 获取单个微信的线程
    return fetchTokenApi(`${wxCloudServiceUrl}/images`, {
        method: "POST",
        body: data
    });
}

export async function getImageByUserIdApi(userId) {
    return fetchTokenApi(`${wxCloudServiceUrl}/images/user/${userId}`, {
        method: "GET",
    });
}