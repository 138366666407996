// src/AppRouter.js
import React, {useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import {useSelector} from 'react-redux';
import {selectUserToken} from './store/slices/userSlice';
import Login from "./pages/Login";
import MainLayout from "./pages/MainLayout/MainLayout";
import Messages from "./pages/MessageList/Messages";
import AgentList from "./pages/AgentList/AgentList";
import SalesMaterial from "./pages/SalesMaterial/SalesMaterial";
import MonitorChat from "./pages/DashBoard/MonitorChat";
import UserManage from "./pages/UserManage/UserManage";
import GroupSendMessage from "./pages/MessageList/GroupMessage/GroupSendMessage";
import AccountManage from "./pages/AccountManage/AccountManage";
import "@arco-design/web-react/dist/css/arco.css";
import {useResponsive} from "./utils/useResponsive";
import setRootPixel from "@arco-design/mobile-react/tools/flexible";
import AddFriendsModal from "./pages/MessageList/Modal/AddFriendsModal";

const PrivateRoute = ({children}) => {
    const token = useSelector(selectUserToken);
    return token ? children : <Navigate to="/login" replace/>;
};

function AppRouter() {
    const [conversationList, setConversationList] = useState([]);
    const [conversationId, setConversationId] = useState(null);
    const {isMobile} = useResponsive();

    useEffect(() => {
        if (isMobile) {
            // 注入 CDN 链接加载移动端 CSS
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'https://unpkg.com/@arco-design/mobile-react@2.34.0/dist/style.min.css'; // 使用 CDN 链接
            document.head.appendChild(link);

            // 调用 setRootPixel
            try {
                setRootPixel();
            } catch (err) {
                console.error('执行 setRootPixel 失败', err);
            }

            // 清理函数：在组件卸载时移除动态添加的 CSS
            return () => {
                document.head.removeChild(link);
            };
        }
    }, [isMobile]);

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <MainLayout
                                conversationList={conversationList}
                                setConversationId={setConversationId}
                            />
                        </PrivateRoute>
                    }
                >
                    {/* 默认子路由重定向到 /messages */}
                    <Route index element={<Navigate to="/messages" replace/>}/>
                    <Route path="messages" element={<Messages/>}/>
                    <Route path="messages/:wx_id/:to_wx_id" element={<Messages/>}/> {/* 更新这行来处理两个参数的路由 */}
                    <Route path="userManage" element={<UserManage/>}/>
                    <Route path="employees" element={<AgentList/>}>
                        <Route path=":id" element={<AgentList/>}/>
                    </Route>
                    <Route path="files" element={<SalesMaterial/>}/>
                    <Route path="files/:id" element={<SalesMaterial/>}/>
                    <Route path="group/:wx_id" element={<GroupSendMessage/>}/>
                    <Route path="account" element={<AccountManage/>}/>
                    <Route path="addFriends" element={<AddFriendsModal/>}/>
                    <Route
                        path="statistics"
                        element={
                            <MonitorChat
                                setConversationList={setConversationList}
                                conversationId={conversationId}
                                setConversationId={setConversationId}
                            />
                        }
                    />
                </Route>
                <Route path="*" element={<Navigate to="/messages" replace/>}/>
            </Routes>
        </Router>
    );
}

export default AppRouter;