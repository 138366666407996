import React from "react";
import { Avatar, Button, Cell } from "@arco-design/mobile-react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearCurrentUser,
  clearSelectedEmployee,
} from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";

const AccountManage = () => {
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearCurrentUser()); // 清除 Redux 中的 currentUser
    dispatch(clearSelectedEmployee()); // 清除 Redux 中的 selectedEmployee
    navigate("/login");
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "var(--arcoblue-1)" }}>
      <Cell.Group>
        <Cell
          icon={
            <Avatar
              size="small"
              shape="square"
              // textAvatar={user?.username.charAt(0).toUpperCase()}
              src={'https://sf1-cdn-tos.toutiaostatic.com/obj/arco-mobile/_static_/small_image_6.jpeg'}
              avatarName={user?.username}
            />
          }
        />
      </Cell.Group>

      <div style={{ padding: "16px" }}>
        <Button
          onClick={handleLogout}
          status="danger"
          style={{ width: "100%" }}
        >
          退出账号
        </Button>
      </div>
    </div>
  );
};

export default AccountManage;
