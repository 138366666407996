import React, { useEffect, useState } from 'react';
import { Button, List, Checkbox, Space, Avatar, Typography, Message } from '@arco-design/web-react';
import { getWxChatsApi, getContactsApi, sendMessageApi } from '../../../../api/normalApi';
import { chatGroup } from '../../../../api/openaiAPI';
// import OpenAI from 'openai';

// const openai = new OpenAI({
//     baseURL: "https://baseurl.replit.app",
//     apiKey: "",
//     dangerouslyAllowBrowser: true,
// });

export const ChatCheckList = ({ chatItem, setChatItem, currentWxAccount, prompt, groupSendLoading, setGroupSendLoading }) => {
    const [chatList, setChatList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [successItems, setSuccessItems] = useState([]);  
    const [skippedItems, setSkippedItems] = useState([]); 
    const groupChats = chatList.filter(chat =>
        chat.is_group === true &&
        contactList.some(contact => contact.to_user_id === chat.to_wx_id)
    );
    const privateChats = chatList.filter(chat => chat.is_group === false);

    const handleCheck = async (item) => {
        let isChecked = true
        // 当有新的勾选时，清除之前的成功和跳过状态
        setSuccessItems([]);
        setSkippedItems([]);
        
        setCheckedItems(prev => {
            isChecked = prev.some(checkedItem => checkedItem.nickname === item.nickname);
            if (isChecked) {
                return prev.filter(checkedItem => checkedItem.nickname !== item.nickname);
            } else {
                return [...prev, item];
            }
        });
        if (!checkedItems.some(checkedItem => checkedItem.nickname === item.nickname)) {
            const res = await handleSend(item);
            console.log(res)
        }
    };

    const handleSend = async (item, manual = true) => {
        try {
            const cachedReply = localStorage.getItem(`${item.thread_id}-reply`);
            if (cachedReply) {
                return { reply: cachedReply };
            } else {
                const res = await chatGroup({
                    thread_id: item.thread_id,
                    employee_id: currentWxAccount.assistant_id,
                    group_prompt: prompt,
                    manual: manual,
                });
                if (res?.reply) {
                    localStorage.setItem(`${item.thread_id}-reply`, res.reply);
                }
                return res;
            }

        } catch (error) {
            console.error('Request failed', error);
        }
    };

    const handleItemClick = (item, e) => {
        if (e.target.closest('.arco-checkbox')) {
            return;
        }
        setChatItem(item);
    };

    useEffect(() => {
        Promise.all([
            getWxChatsApi(currentWxAccount.wx_id, true),
            getContactsApi(currentWxAccount.wx_id)
        ]).then(([chatsRes, contactsRes]) => {
            setChatList(chatsRes.data);
            setContactList(contactsRes.contacts);
        });
    }, [currentWxAccount]);

    useEffect(() => {
        if (chatList.length > 0) {
            setChatItem(chatList[0]); 
        }
        // eslint-disable-next-line
    }, [chatList]);

    useEffect(() => {
        if (groupSendLoading === true) {
            const processItems = async () => {
                try {
                    const processResults = await Promise.all(checkedItems.map(async (item) => {
                        let reply = localStorage.getItem(`${item.thread_id}-reply`);
                        console.log(`Processing item ${item.nickname}, reply:`, reply); // 调试日志
                        
                        if (!reply) {
                            const res = await handleSend(item);
                            reply = res?.reply;
                            console.log(`Got new reply for ${item.nickname}:`, reply); // 调试日志
                        }
                        
                        // 清除所有被处理项目的缓存
                        localStorage.removeItem(`${item.thread_id}-reply`);
                        
                        // 明确检查 reply === "null"
                        if (reply === "null") {
                            console.log(`Item ${item.nickname} marked as skipped due to null reply`); // 调试日志
                            return { item, success: false, skipped: true };
                        }
                        
                        if (reply) {
                            await groupSendMessage(item, reply);
                            console.log(`Item ${item.nickname} marked as success`); // 调试日志
                            return { item, success: true, skipped: false };
                        }
                        
                        console.log(`Item ${item.nickname} marked as skipped due to no reply`); // 调试日志
                        return { item, success: false, skipped: true };
                    }));

                    const successfulItems = processResults
                        .filter(r => r.success)
                        .map(r => r.item);
                    
                    const skippedItems = processResults
                        .filter(r => r.skipped)
                        .map(r => r.item);
                    
                    console.log('Final successful items:', successfulItems.map(i => i.nickname));
                    console.log('Final skipped items:', skippedItems.map(i => i.nickname));
                    
                    setSuccessItems(successfulItems);
                    setSkippedItems(skippedItems);

                    // 清除选中状态
                    setCheckedItems([]);

                    const successCount = successfulItems.length;
                    const skippedCount = skippedItems.length;
                    
                    if (successCount > 0 || skippedCount > 0) {
                        let message = `成功发送给${successCount}个联系人`;
                        if (skippedCount > 0) {
                            message += `，${skippedCount}个联系人因回复为空而跳过`;
                        }
                        Message.success(message);
                    }
                } catch (error) {
                    console.error('Error processing items:', error);
                    Message.error('发送过程中出现错误');
                } finally {
                    setGroupSendLoading(false);
                }
            };

            processItems();
        }
        // eslint-disable-next-line
    }, [groupSendLoading]);

    const handleSelectAll = (type) => {
        if (type === 'group') {
            const allSelected = groupChats.every(chat =>
                checkedItems.some(checkedItem => checkedItem.nickname === chat.nickname)
            );
            setCheckedItems(prev => {
                if (allSelected) {
                    return prev.filter(item => !groupChats.some(chat => chat.nickname === item.nickname));
                } else {
                    return [...prev, ...groupChats.filter(chat =>
                        !prev.some(checkedItem => checkedItem.nickname === chat.nickname)
                    )];
                }
            });
        } else if (type === 'private') {
            const allSelected = privateChats.every(chat =>
                checkedItems.some(checkedItem => checkedItem.nickname === chat.nickname)
            );
            setCheckedItems(prev => {
                if (allSelected) {
                    return prev.filter(item => !privateChats.some(chat => chat.nickname === item.nickname));
                } else {
                    return [...prev, ...privateChats.filter(chat =>
                        !prev.some(checkedItem => checkedItem.nickname === chat.nickname)
                    )];
                }
            });
        }
    };

    const groupSendMessage = async (item, reply) => {
        const back_data = {
            wx_user_id: currentWxAccount.wx_id,
            to_user_id: item.to_wx_id,
            msg: reply,
        };
        try {
            await sendMessageApi(back_data).then((response) => {
                // if (response.status === 200) {
                //     await openai.beta.threads.messages.create(
                //         currentWxAccount.thread_id,
                //         {
                //             role: "assistant", content: reply,
                //             metadata: {
                //                 actual_nickname: currentWxAccount.nickname,
                //                 actual_user_id: currentWxAccount.wx_id,
                //             }
                //         }
                //     );
                //     console.log(response)
                // }
            });

        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    return (
        <Space direction='vertical' style={{ width: '28vw', height: '92vh', marginTop: 2 }}>
            <Space direction='horizontal' align='start'>
                <Space direction={"vertical"}>
                    <Space align={"center"}>
                        <h3 style={{ margin: 0 }} onClick={() => handleSelectAll('group')}>群聊</h3>
                        <Button type={"outline"} size={"small"} onClick={() => handleSelectAll('group')}>全选</Button>
                    </Space>
                    <List
                        style={{
                            height: '87vh',
                            overflowY: 'auto',
                            width: '16vw',
                            scrollbarWidth: 'thin',
                            scrollbarColor: "#EEE white"
                        }}
                        dataSource={groupChats}
                        render={(item, index) => (
                            <List.Item
                                key={index}
                                style={{
                                    backgroundColor: successItems.some(i => i.id === item.id) ? '#f0fff0' : 
                                          chatItem?.id === item.id ? '#f0f0f0' : 'transparent',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s'
                                }}
                                onClick={(e) => handleItemClick(item, e)}
                                actions={[
                                    <Checkbox
                                        checked={checkedItems.some(checkedItem => checkedItem.nickname === item.nickname)}
                                        onChange={() => handleCheck(item)}
                                    />
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar>
                                        {item.head_url ?
                                            <img src={item.head_url} alt={item.head_url} />
                                            : item.nickname.slice(0, 1)}
                                    </Avatar>}
                                    title={item.nickname}
                                    description={<Typography.Ellipsis
                                        ellipsis style={{ fontSize: '14px', color: '#1D2129' }}
                                        row={1}
                                    >
                                        {item.last_message}
                                    </Typography.Ellipsis>}
                                />
                            </List.Item>
                        )}
                    />
                </Space>
                <Space direction={"vertical"} style={{ margin: 0 }}>
                    <Space align={"center"}>
                        <h3 style={{ margin: 0 }}>私聊</h3>
                        <Button type={"outline"} size={"small"} onClick={() => handleSelectAll('private')}>全选</Button>
                        <span>已选择 {checkedItems.length} 位好友</span>
                    </Space>
                    <List
                        style={{
                            height: '87vh',
                            overflowY: 'auto',
                            width: '16vw',
                            scrollbarWidth: 'thin',
                            scrollbarColor: "#EEE white",
                        }}
                        dataSource={privateChats}
                        render={(item, index) => {
                            // 使用 thread_id 进行比较而不是 id
                            const isSuccess = successItems.some(i => i.thread_id === item.thread_id);
                            const isSkipped = skippedItems.some(i => i.thread_id === item.thread_id);
                            
                            const backgroundColor = isSkipped ? '#ffebeb' :
                                                 isSuccess ? '#f0fff0' :
                                                 chatItem?.id === item.id ? '#f0f0f0' :
                                                 'transparent';
                            
                            return (
                                <List.Item
                                    key={index}
                                    style={{
                                        backgroundColor,
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s'
                                    }}
                                    onClick={(e) => handleItemClick(item, e)}
                                    actions={[
                                        <Checkbox
                                            checked={checkedItems.some(checkedItem => checkedItem.nickname === item.nickname)}
                                            onChange={() => handleCheck(item)}
                                        />
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar>{item.head_url ?
                                                <img src={item.head_url} alt={item.head_url} /> : item.nickname.slice(0, 1)}
                                            </Avatar>}
                                        title={item.nickname}
                                        description={
                                            <Typography.Ellipsis
                                                ellipsis style={{ fontSize: '14px', color: '#1D2129',width:"10vw"}}
                                                row={1}
                                            >
                                                {item.last_message}
                                            </Typography.Ellipsis>}
                                    />
                                </List.Item>
                            );
                        }}
                    />
                </Space>
            </Space>
        </Space>
    );
};