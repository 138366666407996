// components/WechatLogin/WechatLogin.js
import React, {useState, useEffect} from 'react';
import {Button, Message, Modal, Space, Switch, Select} from "@arco-design/web-react";
import {QRCodeSVG} from "qrcode.react";
import {checkWechatLoginApi, getWechatQRCodeApi} from "../../../../../api/normalApi"
import {IPV6_PREFIX_MAP, REGION_MAP} from './IPRegionMap';

const Option = Select.Option;

const WechatLogin = ({
                         visible,
                         onClose,
                         onSuccess,
                         currentAccount = null,
                     }) => {
    const [qrCode, setQrCode] = useState(null);
    const [intervalActive, setIntervalActive] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [countdown, setCountdown] = useState(180);
    const [wxInstanceName, setWxInstanceName] = useState(null);
    const [appid, setAppid] = useState("");
    const [privateSwitchChecked, setPrivateSwitchChecked] = useState(true);
    const [globalSwitchChecked, setGlobalSwitchChecked] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState('440000'); // 默认广东

    // 获取IP地址并设置地区
    const getIpAddress = async () => {
        try {
            const response = await fetch('https://jsonip.com');
            const data = await response.json();
            const newRegionId = getRegionIdFromIP(data.ip);
            setSelectedRegion(newRegionId);
        } catch (error) {
            console.error('获取IP地址失败:', error);
        }
    };

    // 从IP获取地区ID
    const getRegionIdFromIP = (ip) => {
        const defaultRegionId = '440000'; // 默认广东
        if (!ip) return defaultRegionId;

        if (ip.includes(':')) {
            const ipPrefix = ip.toLowerCase();

            // 检查具体匹配
            for (const [prefix, regionId] of Object.entries(IPV6_PREFIX_MAP)) {
                if (ipPrefix.startsWith(prefix)) {
                    return regionId;
                }
            }

            // 运营商级别匹配
            if (ipPrefix.startsWith('240e')) return '440000';      // 电信默认广东
            if (ipPrefix.startsWith('2408')) return '110000';      // 联通默认北京
            if (ipPrefix.startsWith('2409')) return '310000';      // 移动默认上海
        }

        return defaultRegionId;
    };

    // 组件加载时获取IP
    useEffect(() => {
        if (visible) {
            getIpAddress();
        }
    }, [visible]);

    // 处理登录
    const handleLogin = async (account = null) => {
        if (isLoggingIn) {
            Message.warning("当前已有登录进行中");
            return;
        }

        try {
            const data = await getWechatQRCodeApi(account?.appid || "", selectedRegion);
            if (data.data.ret === 500) {
                if (data.data.msg === "微信已登录，请勿重复登录。" && account) {
                    onSuccess(account, {
                        private_auto_reply: privateSwitchChecked,
                        group_auto_reply: globalSwitchChecked
                    });
                    return;
                }
                Message.error(data.data.msg);
                setIsLoggingIn(false);
            } else {
                setQrCode(data.data.data.qrData);
                setWxInstanceName(data.data.data.uuid);
                setAppid(data.data.data.appId);
                setIntervalActive(true);
                setIsLoggingIn(true);
            }
        } catch (err) {
            console.log(err);
            Message.error("登录失败，请重试");
            setIsLoggingIn(false);
        }
    };

    // 取消登录
    const cancelLogin = () => {
        setCountdown(180);
        setIntervalActive(false);
        setQrCode(null);
        setIsLoggingIn(false);
        setWxInstanceName(null);
        onClose();
    };

// 倒计时效果
    useEffect(() => {
        let countdownId;
        if (visible && qrCode) {
            setCountdown(180);
            countdownId = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(countdownId);
                        cancelLogin();
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => {
            if (countdownId) clearInterval(countdownId);
        };
    }, [visible, qrCode]);

// 登录状态检查
    useEffect(() => {
        let intervalId;
        if (intervalActive && wxInstanceName) {
            intervalId = setInterval(async () => {
                try {
                    const loginResponse = await checkWechatLoginApi(wxInstanceName, appid);
                    if (loginResponse.data.msg !== "操作成功") {
                        clearInterval(intervalId);
                        setIntervalActive(false);
                        setIsLoggingIn(false);
                        setQrCode(null);

                        if (loginResponse.data.msg === "检测登录失败") {
                            Message.error(loginResponse.data.msg);
                        } else {
                            Message.success("登录成功");
                            onSuccess(currentAccount, {
                                private_auto_reply: privateSwitchChecked,
                                group_auto_reply: globalSwitchChecked
                            });
                        }
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    setIntervalActive(false);
                    setQrCode(null);
                    setIsLoggingIn(false);
                    Message.error("登录失败，请重试");
                }
            }, 3000);
        }
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [intervalActive, wxInstanceName, appid, currentAccount, privateSwitchChecked, globalSwitchChecked, onSuccess]);

    // 监听 visible 变化自动开始登录
    useEffect(() => {
        if (visible && !currentAccount) {
            handleLogin();
        }
    }, [visible]);

    // 原来的 useEffect 保持不变，处理重新登录的情况
    useEffect(() => {
        if (visible && currentAccount) {
            handleLogin(currentAccount);
        }
    }, [visible, currentAccount]);

    // 组件卸载时清理
    useEffect(() => {
        return () => {
            setIntervalActive(false);
            setQrCode(null);
            setIsLoggingIn(false);
            setWxInstanceName(null);
            setCountdown(180);
        };
    }, []);

    return (
        <Modal
            title={currentAccount ? `重新登录: ${currentAccount.nickname}` : "登录新微信"}
            visible={visible}
            onCancel={cancelLogin}
            footer={null}
        >
            <div style={{textAlign: 'center', padding: '20px'}}>
                <div style={{marginBottom: '20px'}}>
                    <Space>
                        <span>登录地区：</span>
                        <Select
                            value={selectedRegion}
                            onChange={setSelectedRegion}
                            style={{width: 120}}
                        >
                            {Object.entries(REGION_MAP).map(([id, name]) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </Space>
                </div>
                {qrCode ? (
                    <Space direction={"vertical"}>
                        <div style={{marginBottom: '20px'}}>
                            <h3>使用实名认证/绑过卡的微信扫码登录帐号接入</h3>
                        </div>
                        <QRCodeSVG value={qrCode} size={200}/>
                        <div style={{marginTop: '20px', color: countdown <= 30 ? "#ff4d4f" : "#666"}}>
                            剩余时间：{countdown}秒
                        </div>
                        <Space style={{marginTop: "10px"}}>
                            <span>自动回复设置：</span>
                            <span>私聊</span>
                            <Switch
                                size="large"
                                checked={privateSwitchChecked}
                                onChange={setPrivateSwitchChecked}
                                checkedText='开启' uncheckedText='关闭'
                            />
                            <span>群聊</span>
                            <Switch
                                size="large"
                                checked={globalSwitchChecked}
                                onChange={setGlobalSwitchChecked}
                                checkedText='开启' uncheckedText='关闭'
                            />
                        </Space>
                        <Button
                            type="primary"
                            onClick={cancelLogin}
                            style={{marginTop: '20px'}}
                        >
                            取消登录
                        </Button>

                    </Space>
                ) : (
                    <div>正在获取二维码...</div>
                )}
            </div>
        </Modal>
    );
};

export default WechatLogin;