import React, { useState, useEffect } from "react";
import {
  Space,
  Card,
  Grid,
  Typography,
  Tabs,
  Button,
} from "@arco-design/web-react";
import EChartsComponent from "./EChartsComponent";
import OpenAI from "openai";
import {
  getChartsByConversationApi,
  getMessagesByConversationApi,
  createChartApi,
} from "../../api/normalApi";

const TabPane = Tabs.TabPane;
const { Row } = Grid;

const DataStatistics = ({ chatBackgrounds, messages, conversationId }) => {
  const [loading, setLoading] = useState(false);
  const [charts, setCharts] = useState([]);
  const [messageId, setMessageId] = useState("");
  const [activeTab, setActiveTab] = useState("summary");
  const fetchMessages = async () => {
    const response = await getMessagesByConversationApi(conversationId);
    console.log('后端返回的 messages:', response.data);
    if (
      response.status === "success" &&
      response.data &&
      response.data.length > 0
    ) {
      setMessageId(response.data[response.data.length - 1].message_id);
    }
  };

  const fetchCharts = async () => {
    try {
      const response = await getChartsByConversationApi(conversationId);
      if (response.status === "success" && response.data) {
        const formattedCharts = response.data.map((chart) => ({
          id: chart.chart_id,
          option: JSON.parse(chart.chart_data),
          createTime: new Date(chart.created_at).toLocaleString(),
        }));
        setCharts(formattedCharts);
      }
    } catch (error) {
      console.error("获取图表数据失败:", error);
    }
  };

  useEffect(() => {
    if (conversationId) {
      fetchCharts();
      fetchMessages();
    }
    // eslint-disable-next-line
  }, [conversationId]);

  useEffect(() => {
    setActiveTab("summary");
  }, [chatBackgrounds]);

  const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
  });

  const handleAIAnalysis = async () => {

    await fetchMessages();
    setActiveTab("statistics");
    setLoading(true);
    try {
      const systemContent = `
你是一位数据分析专家。请分析提供的聊天记录，并返回一个ECharts的option配置对象。
要求：
1. 分析聊天内容的关键主题、互动模式和重要指标
2. 创建多维度的数据可视化，使用合适的图表类型
3. 返回的必须是一个完整的、可直接使用的ECharts option对象
4. 使用恰当的颜色、布局和交互设计
5. 配置必须包含以下要素:
- tooltip: 悬停提示，显示完整的数值信息 
- legend: 图例说明
- label: 在图形上直接显示数值
6. 图表要素规范：
- 判断是否要引入标题title，引入要简洁清晰，不跟数据标签重叠
- 图例位置要合理
- 颜色要协调且有区分度
- 确保数据标签不重叠
- 适当使用网格线增强可读性
- 文字用中文
   
请确保返回的是一个合法的JSON格式的对象，不要包含任何额外的注释或标记。
            `;

      const systemMessage = {
        role: "system",
        content: systemContent,
      };

      const newUserMessage = {
        role: "user",
        content: "请分析这些聊天记录并生成数据可视化配置",
      };

      const completion = await openai.chat.completions.create({
        model: "gpt-4o",
        messages: [systemMessage, ...messages, newUserMessage],
        stream: false,
        response_format: { type: "json_object" },
      });

      const newOption = completion.choices[0].message.content;
      console.log("AI分析结果:", newOption);

      let parsedOption;
      if (typeof newOption === "string") {
        try {
          parsedOption = JSON.parse(newOption);
        } catch {
          parsedOption = newOption;
        }
      } else {
        parsedOption = newOption;
      }

      // 创建新图表
      const chartData = {
        conversation_id: conversationId,
        message_id: messageId,
        chart_data: JSON.stringify(parsedOption),
      };

      // 调用API创建图表
      const response = await createChartApi(chartData);
      if (response.status === "success") {
        // 刷新图表列表
        await fetchCharts();
      }
    } catch (error) {
      console.error("AI分析请求失败:", error);
    } finally {
      setLoading(false);
    }
  };

  const totalChats = chatBackgrounds.reduce(
    (acc, userBackground) => acc + userBackground.chat_list.length,
    0,
  );

  const renderChatSummary = () => (
    <div style={{ position: 'relative', height: '90vh' }}>
      <Space
        direction="vertical"
        style={{
          height: '100%',
          width: "50vw",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "#EEE white",
        }}
      >
        {chatBackgrounds.map((userBackground, index) => (
          <Row key={index} style={{ marginRight: 10 }} align="start">
            {userBackground.chat_list.map((chat, index) => (
              <Card
                key={index}
                title={chat.chat_name}
                style={{
                  width: 300,
                  marginRight: 10,
                  marginBottom: 10,
                }}
              >
                <Typography.Ellipsis rows={4}>{chat.summary}</Typography.Ellipsis>
              </Card>
            ))}
          </Row>
        ))}
      </Space>
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        marginTop:10
      }}>
        <Button type="primary" onClick={handleAIAnalysis}>
          对聊天分析AI生成图表
        </Button>
      </div>
    </div>
  );

  const renderStatistics = () => (
    <div style={{ position: 'relative', height: '90vh' }}>
      <Space
        direction="vertical"
        style={{
          width: "49vw",
          height: '100%',
          overflowY: "auto",
          scrollbarWidth: "thin",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {charts.map((chart, index) => (
            <Card
              key={chart.id}
              style={{ width: "100%" }}
              title={`分析图表 ${index + 1} (${chart.createTime})`}
            >
              <div
                style={{
                  width: "100%",
                  height: "60vh",
                  position: "relative",
                }}
              >
                <EChartsComponent
                  key={`chart-${chart.id}`}
                  option={chart.option}
                />
              </div>
            </Card>
          ))}
        </div>
      </Space>
      <div style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
      }}>
        <Button type="primary" loading={loading} onClick={handleAIAnalysis}>
          {loading ? "分析中..." : "对聊天分析AI生成图表"}
        </Button>
      </div>
    </div>
  );

  return (
    <Space
      direction="vertical"
      style={{
        height: "100vh",
      }}
    >
      <Tabs
        activeTab={activeTab}
        onChange={(key) => setActiveTab(key)}
        style={{ width: "100%", marginTop: 5 }}
      >
        <TabPane key="summary" title={`聊天总结-${totalChats}个`}>
          {renderChatSummary()}
        </TabPane>
        <TabPane key="statistics" title={`统计图表-${charts.length}个`}>
          {renderStatistics()}
        </TabPane>
      </Tabs>
    </Space>
  );
};

export default DataStatistics;
