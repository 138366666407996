// src/pages/MessageList/Modal/NotificationSettingModal.jsx

import React, { useState, useEffect } from 'react';
import {
    Modal,
    List,
    Avatar,
    Space,
    Typography,
    Button,
    Message,
    Empty
} from '@arco-design/web-react';
import { IconCustomerService } from '@arco-design/web-react/icon';
import {getGroupThreadsApi, getNotificationThreadApi, updateThreadNotificationApi, getContactsApi} from "../../../api/normalApi";

const NotificationSettingModal = ({ visible, setVisible, wxId }) => {
    const [groupList, setGroupList] = useState([]);
    const [currentNotificationGroup, setCurrentNotificationGroup] = useState(null);
    const [loading, setLoading] = useState(false);

    // 获取群组列表
    const fetchGroups = async () => {
        try {
            setLoading(true);
            const [allGroups, contactsResponse] = await Promise.all([
                getGroupThreadsApi(wxId),
                getContactsApi(wxId)
            ]);
            if (allGroups?.status === "success" && contactsResponse?.status === 200) {
                const contactIds = new Set(contactsResponse.contacts.map(contact => contact.to_user_id));
                const validGroups = allGroups.data.filter(group => 
                    group.is_group && contactIds.has(group.to_wx_id)
                );
                setGroupList(validGroups);
            } else {
                console.log("获取群组列表失败", allGroups);
                Message.error('获取群组列表失败');
            }
        } catch (error) {
            console.log("获取群组列表出错", error);
            Message.error('获取群组列表失败');
        } finally {
            setLoading(false);
        }
    };

    // 获取当前通知群组
    const fetchCurrentNotificationGroup = async () => {
        try {
            await getNotificationThreadApi(wxId).then((res) => {
                // console.log(res);
                if (res?.status === "success") {  

                    const groupData = res.data;
                    setCurrentNotificationGroup(groupData);
                } else {
                    setCurrentNotificationGroup(null);
                    console.log("获取当前通知群组失败", res);
                }
            });

        } catch (error) {
            console.log("获取当前通知群组出错", error);
            setCurrentNotificationGroup(null);
        }
    };

    useEffect(() => {
        if (visible) {
            fetchGroups();
            fetchCurrentNotificationGroup();
        }
        // eslint-disable-next-line
    }, [visible, wxId]);

    // 设置通知群组
    const handleSetNotification = async (thread) => {
        try {
            setLoading(true);
            const response = await updateThreadNotificationApi(thread.id, true);
            if (response?.status === "success") {
                Message.success('设置成功');
                await fetchCurrentNotificationGroup();
                await fetchGroups();
            } else {
                console.log("设置通知群组失败", response);
                // 显示具体的错误信息
                Message.error(response?.data?.message || '设置失败：'+response?.message);
            }
        } catch (error) {
            console.log("设置通知群组出错", error.response?.data);
            // 从错误响应中获取具体信息
            Message.error(error.response?.data?.message || '设置失败');
        } finally {
            setLoading(false);
        }
    };

    // 取消通知群组
    const handleCancelNotification = async (thread) => {
        try {
            setLoading(true);
            const response = await updateThreadNotificationApi(thread.id, false);
            if (response?.status === "success") {
                Message.success('取消成功');
                setCurrentNotificationGroup(null);
                await fetchGroups();
            } else {
                console.log("取消通知群组失败", response);
                Message.error('取消失败');
            }
        } catch (error) {
            console.log("取消通知群组出错", error);
            Message.error('取消失败');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="配置通知群组"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            style={{ width: 580 }}
            unmountOnExit={true}
        >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <div>
                    <Typography.Title heading={6} style={{ marginBottom: 12 }}>
                        当前通知群
                    </Typography.Title>
                    {currentNotificationGroup ? (
                        <List>
                            <List.Item
                                key={currentNotificationGroup.id}
                                actions={[
                                    <Button
                                        type="secondary"
                                        status="danger"
                                        onClick={() => handleCancelNotification(currentNotificationGroup)}
                                        loading={loading}
                                        size="small"
                                    >
                                        取消通知
                                    </Button>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar shape="square">
                                            {currentNotificationGroup.head_url ? (
                                                <img
                                                    alt="头像"
                                                    src={currentNotificationGroup.head_url}
                                                    style={{ width: '100%', height: '100%' }}
                                                />
                                            ) : (
                                                currentNotificationGroup.nickname.charAt(0)
                                            )}
                                        </Avatar>
                                    }
                                    title={currentNotificationGroup.nickname}
                                />
                            </List.Item>
                        </List>
                    ) : (
                        <Empty
                            icon={<IconCustomerService style={{ fontSize: 24, color: 'rgb(var(--primary-6))' }}/>}
                            description="暂未设置通知群组"
                        />
                    )}
                </div>

                <div>
                    <Typography.Title heading={6} style={{ marginBottom: 12 }}>
                        可选群组
                    </Typography.Title>
                    <List
                        loading={loading}
                        bordered={false}
                        style={{ maxHeight: '50vh', overflowY: 'auto' }}
                        dataSource={groupList.filter(group =>
                            !currentNotificationGroup || group.id !== currentNotificationGroup.id
                        )}
                        render={(item) => (
                            <List.Item
                                key={item.id}
                                actions={[
                                    <Button
                                        type="primary"
                                        onClick={() => handleSetNotification(item)}
                                        loading={loading}
                                        size="small"
                                        disabled={currentNotificationGroup !== null}  
                                    >
                                        {currentNotificationGroup !== null ? '取消当前通知群才能设置' : '设置为通知群'}
                                    </Button>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar shape="square">
                                            {item.head_url ? (
                                                <img
                                                    alt="头像"
                                                    src={item.head_url}
                                                    style={{ width: '100%', height: '100%' }}
                                                />
                                            ) : (
                                                item.nickname.charAt(0)
                                            )}
                                        </Avatar>
                                    }
                                    title={item.nickname}
                                />
                            </List.Item>
                        )}
                        emptyNode={
                            <Empty
                                description="暂无可选群组"
                            />
                        }
                    />
                </div>
            </Space>
        </Modal>
    );
};

export default NotificationSettingModal;