// components/WechatLogin/IPRegionMap.js

// IPv6前缀到地区的映射关系
export const IPV6_PREFIX_MAP = {
    // 电信IPv6前缀映射 (240e)
    '240e:0:': '110000',    // 北京
    '240e:1:': '310000',    // 上海
    '240e:2:': '440000',    // 广东
    '240e:3:': '320000',    // 江苏
    '240e:4:': '330000',    // 浙江
    '240e:5:': '370000',    // 山东
    '240e:6:': '420000',    // 湖北
    '240e:7:': '430000',    // 湖南
    '240e:8:': '350000',    // 福建
    '240e:9:': '500000',    // 重庆
    '240e:10:': '510000',   // 四川

    // 联通IPv6前缀映射 (2408)
    '2408:8000:': '110000', // 北京
    '2408:8200:': '310000', // 上海
    '2408:8400:': '440000', // 广东
    '2408:8600:': '320000', // 江苏
    '2408:8800:': '330000', // 浙江
    '2408:8a00:': '370000', // 山东
    '2408:8c00:': '420000', // 湖北

    // 移动IPv6前缀映射 (2409)
    '2409:8000:': '110000', // 北京
    '2409:8200:': '310000', // 上海
    '2409:8400:': '440000', // 广东
    '2409:8600:': '320000', // 江苏
    '2409:8800:': '330000', // 浙江
    '2409:8a00:': '370000', // 山东
    '2409:8c00:': '420000'  // 湖北
};

export const REGION_MAP = {
    '110000': '北京',
    '310000': '上海',
    '440000': '广东',
    '320000': '江苏',
    '330000': '浙江',
    '370000': '山东',
    '420000': '湖北',
    '430000': '湖南',
    '350000': '福建',
    '500000': '重庆',
    '510000': '四川'
};