// src/pages/MessageList/Modal/AddFriendsModal.js
import React, {useEffect, useState} from 'react';
import {
    Space,
    Upload,
    Message,
    Table,
    Button,
    Input,
    Tabs,
    Tag, Avatar,
} from '@arco-design/web-react';
import * as XLSX from 'xlsx';
import {
    getWeChats,
    getWeChatByUserID,
    createWeChatByUserID,
    distributeAddFriends,
    updateAddWords, getUserData, startAddFriends, checkAddFriends
} from '../../../api/normalApi';
import {useSelector} from 'react-redux';

const TabPane = Tabs.TabPane;

const AddFriendsModel = () => {
    const [data, setData] = useState([]);
    const [requestMessage, setRequestMessage] = useState('');
    const [activeTab, setActiveTab] = useState('');
    const [weChats, setWeChats] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const user = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        getWeChats().then((res) => {
            if (res?.data) {
                setWeChats(res.data);
            }
        });
        getUserData(user.user_id).then((res) => {
            if (res?.data?.add_words) {
                setRequestMessage(res.data.add_words);
                // console.log(res.data.add_words);
            }
        }).catch((err) => {
            console.error('获取用户数据失败:', err);
        });
        // eslint-disable-next-line
    }, []);

    const fetchTableData = (page) => {
        getWeChatByUserID(page, pageSize).then((res) => {
            if (res?.data) {
                const formattedData = res.data.data.map((item, index) => ({
                    key: index,
                    user_id: item.user_id,
                    nickname: item.nickname || '-',
                    phone: item.phone || '-',
                    wechat_id: item.wechat_id || '-',
                    planned_add_time: item.planned_add_time || '',
                    assigned_account: item.assigned_account,
                    status: item.status
                }));
                // console.log(res);
                setData(formattedData);
                setTotal(res.data.total);
            }
        });
    };

    useEffect(() => {
        fetchTableData(currentPage);
    }, [currentPage]);

    // 分离输入和保存的处理函数
    const handleMessageChange = (value) => {
        setRequestMessage(value);
    };

    const handleMessageBlur = () => {
        if (!requestMessage) {
            return;
        }
        if (requestMessage.length > 200) {
            Message.error('话术长度不能超过200字');
            return;
        }
        updateAddWords(user.user_id, requestMessage)
            .then(() => {
                Message.success('话术更新成功');
            })
            .catch((err) => {
                Message.error('话术更新失败');
                console.error('更新话术失败:', err);
            });
    };

    // Mock data for tabs
    const getTabData = () => {
        const onlineAccounts = weChats.filter(w => w.is_online);
        const offlineAccounts = weChats.filter(w => !w.is_online);

        const createAccountTabs = (accounts) => {
            return accounts.reduce((acc, account, index) => {
                const isOnline = account.is_online;
                const key = `${isOnline ? 'online' : 'offline'}_${account.id}`;
                acc[key] = {
                    count: account.apply_count,
                    label: (
                        <Space>
                            <Avatar size={25} shape={"square"}>
                                <img
                                    alt='avatar'
                                    src={account.head_url}
                                />
                            </Avatar>
                            {`${account.nickname}-${isOnline ? '在线' : '不在线'}-分配添加${account.apply_count}个帐号`}

                        </Space>
                    ),
                    color: isOnline ? '#00B42A' : '#FF7D00',
                    bgColor: isOnline ? '#E8FFEA' : '#FFF3E8',
                };
                return acc;
            }, {});
        };

        return {
            ...createAccountTabs(onlineAccounts),
            ...createAccountTabs(offlineAccounts)
        };
    };

    // 定义表格列配置
    const columns = [
        {
            title: '用户名称',
            dataIndex: 'nickname',
            key: 'nickname',
            render: (text) => (
                <Space>
                    {text}
                </Space>
            ),
            width: '250px',
        },
        {
            title: '微信号',
            dataIndex: 'wechat_id',
            key: 'wechat_id',
            width: '150px',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            width: '150px',
        },
        {
            title: '预计添加时间',
            dataIndex: 'planned_add_time',
            key: 'planned_add_time',
            render: (text) => (
                <span>{formatDate(text)}</span>
            ),
            width: '220px',
        },
        {
            title: '分配添加账号',
            dataIndex: 'assigned_account',
            key: 'assigned_account',
            render: (_, record) => {
                const account = weChats.find(w => w.id === record.assigned_account);
                if (!account) return '-';
                return (
                    <Space>
                        <Avatar size={25} shape={"square"}>
                            <img
                                alt='avatar'
                                src={account.head_url}
                            />
                        </Avatar>
                        <Tag color="green">{account.nickname}</Tag>
                    </Space>
                );
            },
            width: '200px',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                switch (status) {
                    case 1:
                        return <Tag color={"green"}>已申请</Tag>;
                    case 2:
                        return <Tag color={"red"}>未找到</Tag>;
                    default:
                        return <Tag>未申请</Tag>
                }
            },
        },
    ];

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const workbook = XLSX.read(e.target.result, {type: 'binary'});
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});

                if (jsonData.length < 2) {
                    Message.error('文件内容为空或格式错误！');
                    return;
                }

                const headerRow = jsonData[0]; // 第一行作为表头
                const dataRows = jsonData.slice(1); // 去掉表头后的内容

                const findColumnIndex = (headers, condition) => {
                    return headers.findIndex(condition);
                };

                const userNameIndex = findColumnIndex(headerRow, (header, index) =>
                    dataRows.some((row) => row[index] && /[\u4e00-\u9fa5]/.test(row[index]))
                );

                const phoneIndex = findColumnIndex(headerRow, (header, index) => {
                    const nonEmptyValues = dataRows
                        .map(row => row[index])
                        .filter(cell => cell && cell.toString().trim());
                    if (nonEmptyValues.length === 0) {
                        return false;
                    }
                    const avgLength = nonEmptyValues.reduce((sum, cell) =>
                        sum + cell.toString().trim().length, 0) / nonEmptyValues.length
                    const isPhoneColumn = Math.abs(avgLength - 11) < 0.0001;
                    return isPhoneColumn;
                });

                const wechatIndex = findColumnIndex(headerRow, (header, index) =>
                    dataRows.some((row) => row[index] && /^[a-zA-Z0-9_]+$/.test(row[index]))
                );

                // Add check for same indices
                const isWechatSameAsPhone = phoneIndex === wechatIndex;

                // 检查每列是否完全为空
                const isRowEmpty = (row) => row.every((cell) => !cell || cell === '-');

                // 检查是否所有列都为 '-'
                const isAllColumnsDash = (row) =>
                    row.every((cell, index) => {
                        if (index === userNameIndex || index === phoneIndex || index === wechatIndex) {
                            return cell === '-';
                        }
                        return true;
                    });

                // 构建提取的数据
                const extractedData = dataRows
                    .filter((row) => !isRowEmpty(row) && !isAllColumnsDash(row)) // 过滤掉空行和全为 '-'
                    .map((row, index) => ({
                        user_id: user.user_id,
                        key: index,
                        nickname: userNameIndex !== -1 ? row[userNameIndex] || '-' : '-',
                        phone: phoneIndex !== -1 ? row[phoneIndex] || '-' : '-',
                        wechat_id: (isWechatSameAsPhone || wechatIndex === -1) ? '-' : (row[wechatIndex] || '-'),
                        planned_add_time: '',
                        assignedAccount: '添加账号',
                        status: '待申请',
                    }));
                setData(extractedData);

                // 创建back_data_list，只包含必要字段且过滤掉无效数据
                const back_data_list = extractedData
                    .filter(item => !(item.phone === '-' && item.wechat_id === '-')) // 过滤掉phone和wechat_id都为空的项
                    .map(({user_id, nickname, phone, wechat_id}) => ({
                        user_id,
                        nickname,
                        phone: phone === '-' ? '' : String(phone),
                        wechat_id: wechat_id === '-' ? '' : String(wechat_id)
                    }));

                getWeChatByUserID(1, pageSize).then((res) => {
                    if (res?.data && res.data.length !== back_data_list.length) {
                        createWeChatByUserID(back_data_list).then((res) => {
                            fetchTableData(1)
                        });
                    }
                });
                Message.success('文件解析成功');
            } catch (error) {
                Message.error('文件解析失败，请检查文件格式');
                console.error('Error parsing file:', error);
            }
        };

        reader.onerror = () => {
            Message.error('文件读取失败！');
        };

        reader.readAsBinaryString(file);
        return false;
    };

    const handleStartAdd = () => {
        // 获取当前小时数
        const currentHour = new Date().getHours();

        distributeAddFriends({
            daily_limit: 10,
            start_date_offset: 0,
            start_hour_offset: 6
        }).then(res => {
            Message.success('分配微信成功');
            fetchTableData(1);
            startAddFriends().then(res => {
                // console.log(res)
                Message.success('任务部署成功');
            })
        })
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';

        const date = new Date(dateString);
        const today = new Date();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const isToday = date.toDateString() === today.toDateString();

        return `${month}月${day}日${isToday ? '(今天)' : ''}-${hours}:${minutes}`;
    };

    return (
        <Space
            style={{width: "80vw", height: '98vh'}}
            align={"start"}
            direction={"vertical"}
        >
            <Space>
                <h3>上传微信/手机号列表批量添加好友</h3>
                <Upload
                    drag
                    accept=".xlsx,.xls"
                    beforeUpload={handleUpload}
                    showUploadList={false}
                    style={{width: '100%', marginLeft: '10px'}}
                >
                    <Button type="primary">
                        <span>上传文件</span>
                    </Button>
                </Upload>
            </Space>
            <Space direction="vertical" style={{width: '100%'}}>
                <Space align='start' style={{marginBottom: "-20px"}}>
                    <Input.TextArea
                        placeholder="填入好友申请话术: 你好，我是XXX，想加你为好友。"
                        value={requestMessage}
                        onChange={handleMessageChange}
                        onBlur={handleMessageBlur}
                        style={{width: 'calc(78vw - 210px)', marginBottom: '16px'}}
                        autoSize={true}
                        maxLength={200}
                    />
                    {data.length > 0 && (
                        <Button type="primary" onClick={handleStartAdd}>
                            开始添加
                        </Button>
                    )}
                </Space>

                <Tabs
                    activeTab={activeTab}
                    onChange={setActiveTab}
                    style={{width: '100%'}}
                >
                    {Object.entries(getTabData()).map(([key, {count, label, color, bgColor}]) => (
                        <TabPane
                            key={key}
                            title={
                                <Tag
                                    color={bgColor}
                                    style={{
                                        color: color,
                                        borderRadius: '5px',
                                        height: 30
                                    }}
                                >
                                    {label}
                                    {/*<span style={{*/}
                                    {/*    marginTop:5,*/}
                                    {/*    marginRight: 2,*/}
                                    {/*    backgroundColor: color,*/}
                                    {/*    color: 'white',*/}
                                    {/*    padding: '2 6px',*/}
                                    {/*    borderRadius: '8px',*/}
                                    {/*    fontSize: '12px'*/}
                                    {/*}}>*/}
                                    {/*    {count}*/}
                                    {/*</span>*/}
                                </Tag>
                            }
                        />
                    ))}
                </Tabs>

                <Table
                    columns={columns}
                    data={data}
                    pagination={{
                        total: total,
                        pageSize: pageSize,
                        current: currentPage,
                        onChange: (page) => setCurrentPage(page),
                        showTotal: true,
                    }}
                    style={{width: '100%'}}
                />
            </Space>
        </Space>
    );
};

export default AddFriendsModel;