import CustomerTable from "./components/CustomerTable";
import {Space} from "@arco-design/web-react";
const UserManage = () => {
    return (
        <Space>
            <CustomerTable/>
        </Space>
    );
};

export default UserManage;