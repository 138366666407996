// src/store/slices/userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentUser: null,
    selectedEmployee: null,
    token: null, // 添加 token
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // 设置当前用户
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload.user; // 假设 response.data 包含 user 和 token
            state.token = action.payload.token;
        },
        // 清空当前用户
        clearCurrentUser: (state) => {
            state.currentUser = null;
            state.token = null;
        },

        // 设置选中的员工
        setSelectedEmployee: (state, action) => {
            state.selectedEmployee = action.payload;
        },
        // 清空选中的员工
        clearSelectedEmployee: (state) => {
            state.selectedEmployee = null;
        },
    },
});

export const {
    setCurrentUser,
    clearCurrentUser,
    setSelectedEmployee,
    clearSelectedEmployee,
} = userSlice.actions;

// 选择器
export const selectCurrentUser = (state) => state.user.currentUser;
export const selectSelectedEmployee = (state) => state.user.selectedEmployee;
export const selectUserToken = (state) => state.user.token; // 添加 token 选择器

export default userSlice.reducer;