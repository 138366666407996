import React, {useCallback, useEffect, useState} from "react";
import {Message, Space} from "@arco-design/web-react";
import EditPage from "./EditPage/EditPage";
import EmployeeTable from "./ListPage/component/EmployeeTable";
import { useSelector } from "react-redux";
import {selectCurrentUser, selectSelectedEmployee} from "../../store/slices/userSlice";
import {getUserData} from "../../api/normalApi";

const AgentList = () => {
  const selectedEmployee = useSelector(selectSelectedEmployee);
  const user = useSelector(selectCurrentUser); // 使用选择器获取 currentUser
  const [userData, setUserData] = useState({ employee_limit: 0, client_limit: 0 }); // 初始状态设置为默认值对象

  // 使用 useEffect 在组件挂载时加载用户数据
  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.user_id) {
        try {
          const { data } = await getUserData(user.user_id);
          setUserData(data); // 更新 userData 状态
        } catch (error) {
          // console.error("加载用户数据失败：", error);
          Message.error("加载用户数据失败，请稍后重试");
        }
      }
    };

    fetchUserData().then(r => {

    });
  }, [user?.user_id]); // 在 user.user_id 变化时重新加载数据


  return (
    <Space align={"start"} style={{ height: "100vh" }}>
      {selectedEmployee ? (
        <EditPage selectedEmployee={selectedEmployee} maxClientSeats={userData.client_limit} />
      ) : (
        <EmployeeTable maxEmployeeSeats={userData.employee_limit} maxClientSeats={userData.client_limit} />
      )}
    </Space>
  );
};

export default AgentList;
