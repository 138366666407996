import React, {useEffect, useState} from 'react';
import {Avatar, Button, Input, Message, Space} from '@arco-design/web-react';
import {ChatCheckList} from './components/ChatCheckList';
import ChatPreview from './components/ChatPreview';
import {getBroadcastPrompt, updateBroadcastPrompt} from "../../../api/normalApi";
import {chatGroup} from "../../../api/openaiAPI";
import SalesData from "../../AgentList/EditPage/components/EditPage/SalesData";
const TextArea = Input.TextArea;

const GroupSendMessage = ({currentAssistant, currentWxAccount, setShowGroupSendMessage}) => {
    const [chatItem, setChatItem] = useState('');
    const [prompt, setPrompt] = useState(''); // 当前输入框的值
    const [originalPrompt, setOriginalPrompt] = useState(''); // 存储初始提示词
    const [groupSendLoading, setGroupSendLoading] = useState(false);

    useEffect(() => {
        // 获取最新提示词
        const fetchPrompt = async () => {
            try {
                const response = await getBroadcastPrompt(currentWxAccount.wx_id);
                setPrompt(response.data); // 更新提示词
                setOriginalPrompt(response.data); // 初始化原始提示词
            } catch (error) {
                console.error('获取提示词失败', error);
            }
        };
        fetchPrompt();
    }, [currentWxAccount.wx_id]);

    const handleBlur = async () => {
        // 检查当前提示词是否与原始提示词不同
        if (prompt.trim() !== originalPrompt.trim()) {
            if (!prompt.trim()) {
                Message.warning('提示词不能为空！');
                return;
            }
            try {
                await updateBroadcastPrompt(currentWxAccount.wx_id, prompt);
                Message.success('提示词保存成功！');
                setOriginalPrompt(prompt); // 更新原始提示词为当前值
            } catch (error) {
                console.error('保存提示词失败', error);
                Message.error('保存提示词失败，请重试！');
            }
        }
    };

    const handleSend = async (manual = true) => {
        try {
            return await chatGroup({
                thread_id: chatItem.thread_id,
                employee_id: currentWxAccount.assistant_id,
                group_prompt: prompt,
                manual: manual,
            })
        } catch (error) {
            console.error('Request failed', error);
        }
    };

    return (
        <Space direction="horizontal" style={{width: '80vw'}} align={"start"}>
            <Space direction="vertical" style={{width: '26vw'}}>
                <Space align={"center"} style={{justifyContent: 'space-between', width: '100%'}}>
                    <h3 style={{margin: 0}}>群发配置</h3>
                    <Space>
                        <Avatar
                            shape="square"
                            style={{backgroundColor: "#3370ff", marginLeft: 5}}
                            size={32}
                        >
                            <img
                                alt='avatar'
                                src={currentWxAccount.head_url}
                            />
                        </Avatar>
                        <h3 style={{margin: 0}}>
                            {currentWxAccount.nickname}
                        </h3>
                    </Space>
                    <Button type={"outline"} onClick={() => setShowGroupSendMessage(false)} size={"small"}>返回</Button>
                </Space>
                <TextArea
                    style={{width: '100%', height: '30vh'}}
                    placeholder="
                          请输入生成消息的提示词
                          例如：最近公司发了新品，转发给客户"
                    value={prompt} // 绑定值
                    onChange={(value) => setPrompt(value)} // 监听变化
                    onBlur={handleBlur} // 失去焦点时触发保存
                />
                <SalesData assistant={currentAssistant}>

                </SalesData>
            </Space>
            <Space direction="vertical" style={{width: '28vw', height: '87vh'}}>
                <ChatPreview chatItem={chatItem} prompt={prompt} handleSend={handleSend}
                             currentWxAccount={currentWxAccount} setGroupSendLoading={setGroupSendLoading}/>
            </Space>
            <ChatCheckList chatItem={chatItem} setChatItem={setChatItem} currentWxAccount={currentWxAccount}
                           prompt={prompt} groupSendLoading={groupSendLoading}
                           setGroupSendLoading={setGroupSendLoading}/>
        </Space>
    );
};

export default GroupSendMessage;