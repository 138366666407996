// src/components/LarkTable.js

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Space } from "@arco-design/web-react";
import { checkUserTable } from "../../../api/normalApi";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../store/slices/userSlice"; // 确保路径正确

export const LarkTable = ({ width = "88vw", customHeight }) => {
    const [iframeUrl, setIframeUrl] = useState(null); // 用于存储动态的 iframe URL
    const [screenHeight, setScreenHeight] = useState(window.innerHeight); // 获取屏幕高度
    const [employeeId, setEmployeeId] = useState(null);
    const user = useSelector(selectCurrentUser); // 使用选择器获取 currentUser
    const navigate = useNavigate();
    const { id } = useParams();  // /files/:id 这样定义路由

    useEffect(() => {
        // console.log(id);
        if (id) {
            setEmployeeId(id);
        }

        // 监听窗口大小变化，动态更新屏幕高度
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        // 如果 user 里面没有 table_url 字段，就跳到登录页面
        if (user.user_id) {
            const user_id = user.user_id;
            checkUserTable(user_id)
                .then((response) => {
                    if (response?.data?.table_url) {
                        setIframeUrl(response.data.table_url); // 设置 iframe URL
                    } else {
                        console.error("table_url not found in response");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user data:", error);
                });
        } else {
            console.error("user or user_id not found");
        }

        // 清理事件监听
        return () => window.removeEventListener("resize", handleResize);
    }, [user, id, navigate]);

    // 确定组件的高度：优先使用传入的 customHeight，否则使用动态屏幕高度
    const effectiveHeight = customHeight || screenHeight;

    return (
        <Space direction="vertical" style={{ width, height: effectiveHeight }}>
            {iframeUrl ? (
                <div style={{ overflow: "hidden", height: effectiveHeight - 10 }}>
                    <iframe
                        title="larkTable"
                        src={iframeUrl}
                        width="100%"
                        height={effectiveHeight + 56} // 增加高度以避免隐藏部分被裁剪
                        style={{
                            border: "none",
                            position: "relative",
                            top: "-65px", // 上移部分
                        }}
                    ></iframe>
                </div>
            ) : (
                <div>Loading...</div> // 在 iframe URL 未加载完成之前的占位内容
            )}
            {employeeId && (
                <Button
                    type="primary"
                    shape="round"
                    style={{
                        position: 'fixed',
                        top: 10,
                        right: 20,
                        zIndex: 9999,
                    }}
                    onClick={() => {
                        navigate(`/employees/${employeeId}`);
                    }}
                >
                    返回
                </Button>
            )}
        </Space>
    );
};