import React, { useState } from 'react';
import { Modal, Upload, Button, Spin, Space } from '@arco-design/web-react';
import { IconUpload } from '@arco-design/web-react/icon';
import { analyzeImage } from '../../../api/openaiAPI';
import ReactMarkdown from "react-markdown";

const UploadRecordModal = ({ setAddBackground }) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [picDescription, setPicDescription] = useState('');
    const [analyzing, setAnalyzing] = useState(false);

    // 将文件转换为Base64
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    // 处理图片分析 —— 接收一个图片内容参数，默认为 state 中的 previewImage
    const handleAnalyze = async (currentImage = previewImage) => {
        setAnalyzing(true);
        try {
            const prompt = `判断图片是什么类型：
            1.如果是聊天记录，就把聊天记录都提取出来按照：
              名称：消息
              名称：消息
              这样的格式直接排列输出，不要有多余的话，注意换行
            2.如果是其他类型，根据图片内容判断是否是图片描述任务，如果是图片描述任务就直接输出图片的文字描述不要有多余的话
            3.如果图片不适合为描述任务输出，酌情分析内容输出`;

            const res = await analyzeImage(prompt, currentImage);
            setPicDescription(res.choices[0].message.content);
        } catch (error) {
            console.error('图片分析失败:', error);
            setPicDescription('图片分析失败，请稍后重试');
        } finally {
            setAnalyzing(false);
        }
    };

    // 处理文件预览（这里直接在预览完成后自动分析）
    const handlePreview = async (file) => {
        // 获取 base64
        let preview = '';
        if (!file.url) {
            preview = await getBase64(file);
        } else {
            preview = file.url;
        }

        // 更新预览图片
        setPreviewImage(preview);
        setPicDescription('');
        setPreviewVisible(true);

        // 关键：在这里直接调用一次分析逻辑
        //      把刚拿到的 base64 传给 handleAnalyze，避免拿不到最新 previewImage
        await handleAnalyze(preview);
    };

    return (
        <Space align="center">
            <Upload
                listType="picture-card"
                imagePreview
                limit={1}
                onPreview={handlePreview}
                beforeUpload={(file) => {
                    // 用户点击上传后，不走真正的上传，而是先转 base64 + 预览 + 分析
                    handlePreview(file).then(() => {});
                    return false; // 阻止默认自动上传到服务器
                }}
            >
                <Button type="primary" icon={<IconUpload />}>
                    上传聊天截图
                </Button>
            </Upload>

            <Modal
                style={{ width: '50vw' }}
                visible={previewVisible}
                title="图片分析"
                onCancel={() => {
                    setPreviewVisible(false);
                    setPicDescription('');
                }}
            >
                <Space align="start">
                    {/* 左侧显示预览图片 */}
                    <Space>
                        <img
                            alt="预览图片"
                            style={{
                                width: '400px',
                                objectFit: 'contain',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                            }}
                            src={previewImage}
                        />
                    </Space>

                    {/* 右侧展示分析结果 */}
                    <Space direction="vertical">
                        <Space>
                            {/* 可保留“分析”按钮，允许用户手动重复分析 */}
                            <Button
                                type="primary"
                                onClick={() => handleAnalyze()}
                                loading={analyzing}
                            >
                                分析
                            </Button>
                            <Button
                                onClick={() => {
                                    setPreviewVisible(false);
                                    setPicDescription('');
                                }}
                            >
                                关闭
                            </Button>
                            {picDescription && (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setAddBackground(picDescription);
                                        setPreviewVisible(false);
                                    }}
                                >
                                    添加到聊天记忆
                                </Button>
                            )}
                        </Space>

                        {/* 分析的加载与显示 */}
                        {analyzing ? (
                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                <Spin dot />
                                <div>正在分析图片...</div>
                            </div>
                        ) : picDescription ? (
                            <ReactMarkdown
                                style={{
                                    padding: '10px',
                                    background: '#f5f5f5',
                                    borderRadius: '4px',
                                    border: '1px solid #ddd',
                                }}
                            >
                                {picDescription}
                            </ReactMarkdown>
                        ) : (
                            <div style={{ textAlign: 'center', padding: '20px', color: '#888' }}>
                                <p>点击“分析”以获取结果</p>
                            </div>
                        )}
                    </Space>
                </Space>
            </Modal>
        </Space>
    );
};

export default UploadRecordModal;